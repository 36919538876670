import React, { useEffect, useState } from 'react';
import InputField from '../components/auth/InputField';
import AuthCrad from '../components/auth/AuthCrad';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import { useAuth } from '../hooks/useAuth';
import Spinner from '../utilities/utils-components/Spinner';

export const ForgotPassword = () => {
  const { forgotPassword, message, loading, error } = useAuth();
  const [email, setEmail] = useState();

  const submitHandler = (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  useEffect(() => {
    setEmail('');
  }, [message]);

  return (
    <AuthCrad title='Mot de passe oublié'>
      {error && (
        <span className=' text-red-600 py-3 px-1 bg-red-50 block'>{error}</span>
      )}
      {message && (
        <span className=' text-green-600 py-3 px-1 bg-green-50 block'>
          {message}
        </span>
      )}
      <Spinner loading={loading} />
      <form onSubmit={submitHandler} className=' flex flex-col space-y-4 mt-4'>
        <InputField
          title='Email'
          type='email'
          placeholder='Entrer votre email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <PrimaryButton text='Réintialiser' />
      </form>
    </AuthCrad>
  );
};
