import AddAds from '../admin/AddAds';
import AdminAds from '../admin/AdminAds';
import CompanyDetail from '../admin/CompanyDetail';
import Dashboard from '../admin/Dashboard';
import DetailsCommande from '../admin/DetailsCommande';
import EdictAds from '../admin/EdictAds';
import MyOrders from '../admin/MyOrders';
import NewService from '../admin/NewService';
import Orders from '../admin/Orders';
import ProfilPage from '../admin/ProfilPage';
import SellerList from '../admin/SellerList';
import ServiceEdict from '../admin/ServiceEdict';
import ServicePage from '../admin/ServicePage';
import Annonces from '../pages/Annonces';
import Company from '../pages/Company';
import ContactUs from '../pages/ContactUs';
import DetailCampany from '../pages/DetailCampany';
import FaqPage from '../pages/FaqPage';
import { ForgotPassword } from '../pages/ForgotPassword';
import Home from '../pages/Home';
import { Login } from '../pages/Login';
import NotFound from '../pages/NotFound';
import ProductDetails from '../pages/ProductDetails';
import QuiSommesNous from '../pages/QuiSommesNous';
import Register from '../pages/Register';
import { ResetPassword } from '../pages/ResetPassword';
import SearchPage from '../pages/SearchPage';
import ServiceDetails from '../pages/ServiceDetails';
import ShippingAddress from '../pages/ShippingAddress';
import SuccessOrder from '../pages/SuccessOrder';
import TermeCondiontion from '../pages/TermeCondiontion';

export function usePages(user, profil) {
  const publicPath = [
    { path: '/', Component: Home, exact: true },
    { path: '/entreprise', Component: Company, exact: true },
    { path: '/connexion', connected: user, exact: true, Component: Login },
    { path: '/inscription', connected: user, exact: true, Component: Register },
    { path: '*', Component: NotFound },
    { path: '/faqs', Component: FaqPage },
    { path: '/annonces', Component: Annonces },
    { path: '/detail-entreprise/:id', Component: DetailCampany },
    { path: '/service/detail/:id', Component: ServiceDetails },
    { path: '/produit/detail/:id', Component: ProductDetails },
    { path: '/mot-de-passe-oublie', Component: ForgotPassword },
    { path: '/recherche', Component: SearchPage },
    { path: '/contactez-nous', Component: ContactUs },
    { path: '/qui-somme-nous', Component: QuiSommesNous },
    { path: '/termes-conditions', Component: TermeCondiontion },
    { path: '/reintialisation/mot-de-passe', Component: ResetPassword },
  ];

  const adminPath = [
    {
      path: '/admin',
      exact: true,
      isloging: user,
      component: Dashboard,
    },
    {
      path: '/profil/edit',
      exact: true,
      isloging: user,
      component: ProfilPage,
    },
    {
      path: '/services',
      exact: true,
      isloging: user,
      component: ServicePage,
    },
    {
      path: '/mes-commandes',
      exact: true,
      isloging: user,
      component: MyOrders,
    },
    {
      path: '/commandes',
      exact: true,
      isloging: user,
      component: Orders,
    },
    {
      path: '/nouveau/service',
      exact: true,
      isloging: user,
      component: NewService,
    },
    {
      path: '/edict/service/:id',
      exact: true,
      isloging: user,
      component: ServiceEdict,
    },
    {
      path: '/commande/:id',
      exact: true,
      isloging: user,
      component: DetailsCommande,
    },
    {
      path: '/addresse/client',
      isloging: user,
      component: ShippingAddress,
    },
    {
      path: '/commande/termine',
      isloging: user,
      component: SuccessOrder,
    },
  ];

  const adminstratorPath = [
    { path: '/admin/annonces', isAdmin: profil?.isAdmin, Component: AdminAds },
    {
      path: '/nouvvelle-annonces',
      isAdmin: profil?.isAdmin,
      Component: AddAds,
    },
    {
      path: '/edict/annonces/:id',
      isAdmin: profil?.isAdmin,
      Component: EdictAds,
    },
    {
      path: '/utilisateurs/list',
      isAdmin: profil?.isAdmin,
      Component: SellerList,
    },
    {
      path: '/admin/users/:id',
      isAdmin: profil?.isAdmin,
      Component: CompanyDetail,
    },
  ];

  return { publicPath, adminPath, adminstratorPath };
}
