import React, { useEffect, useRef, useState } from 'react';
import AuthCrad from '../components/auth/AuthCrad';
import InputField from '../components/auth/InputField';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Spinner from '../utilities/utils-components/Spinner';
import { toast } from 'react-toastify';

export const Login = () => {
  const navigate = useNavigate();
  const { authState, signin, signup, dispatchIfNotCancelled } = useAuth();
  const { user, isloading, error, success } = authState;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    signin(email, password);
  };

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: 'top-center',
      });
    }
  }, [error]);
  return (
    <AuthCrad title='Connexion'>
      <Spinner loading={isloading} />
      <form onSubmit={submitHandler} className=' flex flex-col space-y-4 mt-4'>
        <InputField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          title='Email'
          type='email'
          placeholder='Entrer votre email'
        />
        <InputField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          title='Mot de passe'
          type='password'
          placeholder='Entrer votre mot de passe'
        />

        <div className=' flex space-x-2 justify-end text-[#114cb3]'>
          <span
            onClick={() => navigate('/mot-de-passe-oublie')}
            className=' cursor-pointer hover:text-[#d60e0e]'
          >
            Mot de passe oublié?
          </span>
        </div>

        <PrimaryButton text='Se connecter' />

        <div className=' flex space-x-2 justify-end text-[#114cb3]'>
          <span
            onClick={() => navigate('/inscription')}
            className=' cursor-pointer hover:text-[#d60e0e]'
          >
            Vous n'avez de compte? <span className>Creer un compte</span>
          </span>
        </div>
      </form>
    </AuthCrad>
  );
};
