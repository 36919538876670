import React, { useState } from 'react';
import Layout from '../components/admin/Layout';
import Pagination from '../utilities/utils-components/Pagination';
import { useCollection } from '../hooks/useCollection';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../context/UseContext';
import { priceFormatter } from '../utils/priceFomatter';
import Loader from '../utilities/utils-components/Loader';
import ErreorMessage from '../utilities/utils-components/ErreorMessage';
import NotFound from '../utilities/utils-components/NotFound';

const MyOrders = () => {
  const { user } = useMyContext();
  const navigate = useNavigate();

  const { documents, loading, error } = useCollection(
    'orders',
    '',
    '',
    '',
    '',
    user.uid
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(5);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : error ? (
        <ErreorMessage message={'Erreur de recuperation des commandes'} />
      ) : currentProducts.length === 0 ? (
        <NotFound message='Aucune commande commande trouvé' />
      ) : (
        <>
          {' '}
          <div className=' '>
            <nav className=' bg-[#d6d6d6] py-2 px-4 text-[1.1rem] font-medium'>
              <ul className=' grid grid-cols-7 md:grid-cols-9'>
                <li className=' col-span-2  text-base lg:text-[1rem]'>
                  Services
                </li>
                <li className=' hidden md:block col-span-2  text-base lg:text-[1rem]'>
                  Categorie
                </li>
                <li className=' col-span-2  text-base lg:text-[1rem]'>
                  Quantité
                </li>
                <li className=' col-span-2  text-base lg:text-[1rem]'>
                  Montant
                </li>
                <li className='flex justify-center  text-base lg:text-[1rem]'>
                  Action
                </li>
              </ul>
            </nav>
            <div className='bg-white shadow-sm  '>
              {currentProducts.map((service, index) => (
                <ul
                  key={index}
                  className='  grid grid-cols-7 md:grid-cols-9 border-b-2 border-b-[#d6d6d6] items-center p-4'
                >
                  <li className='flex-wrap col-span-2 flex items-center  '>
                    <div className=' h-12 w-12 mr-2'>
                      <img
                        src={service.productImage}
                        alt=''
                        className=' h-full w-full object-cover'
                      />
                    </div>
                    <h2 className=' font-medium text-[0.9rem] md:text-[1rem]'>
                      {service.title}{' '}
                    </h2>
                  </li>
                  <li className=' hidden md:block md:col-span-2'>
                    <h3 className=' text-[1.1rem] font-medium opacity-70'>
                      {service.category}
                    </h3>
                  </li>
                  <li className=' col-span-2'>
                    <h3 className=' text-[0.9rem] md:text-[1.1rem] font-medium opacity-70'>
                      {service.qty}
                    </h3>
                  </li>
                  <li className=' col-span-2'>
                    <h3 className=' text-[0.9rem] md:text-[1.1rem] font-semibold opacity-70 '>
                      {priceFormatter(service.totalAmout)} FCFA
                    </h3>
                  </li>
                  <li className='  items-center space-x-2 flex justify-center'>
                    <button title='Détails'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        className='w-5 h-5 text-[#114cb3]'
                      >
                        <path d='M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z' />
                        <path
                          fillRule='evenodd'
                          d='M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </button>
                  </li>
                </ul>
              ))}
            </div>
          </div>
          <Pagination
            documents={documents}
            productsPerPage={productsPerPage}
            currentPage={currentPage}
            paginate={paginate}
          />
        </>
      )}
    </Layout>
  );
};

export default MyOrders;
