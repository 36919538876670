import React from 'react';
import { Link } from 'react-router-dom';

const NotreEquipe = () => {
  return (
    <div>
      <h2 className=' text-[1.1rem] md:text-[1.6rem] font-semibold capitalize'>
        Notre Equipe
      </h2>
      <p>
        Notre équipe dévouée travaille ensemble pour offrir des solutions
        innovantes et répondre aux besoins de nos clients.
      </p>
      <div className=' gap-5 grid teams  mt-3'>
        <div className=' p-5 bg-[#dfdfdf] shadow-md rounded-lg flex flex-col items-center justify-center'>
          <div>
            <img
              src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              alt='image'
              className=' rounded-full h-24 w-24'
            />
          </div>
          <h3 className=' text-[1.2rem] font-bold'>Jhone Joel</h3>
          <span>Responsable</span>
          <ul className=' mt-1 space-x-1 flex items-center justify-center'>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={'seller.facebook'}
              className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
            >
              <svg
                className='h-3 text-white'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 320 512'
                fill='#FFFFFF'
              >
                <path d='M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z' />
              </svg>
            </Link>
            <Link
              to={'seller.instagram'}
              target='_blank'
              rel='noopener noreferrer'
              className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#ea0b86]'
            >
              <svg
                className='h-3 text-white'
                fill='#FFFFFF'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z' />
              </svg>
            </Link>
            <Link
              to={'seller.linkedin'}
              target='_blank'
              rel='noopener noreferrer'
              className=' rounded-full w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
            >
              <svg
                className='h-3 text-white'
                fill='#FFFFFF'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z' />
              </svg>
            </Link>
          </ul>
        </div>
        <div className=' p-5 bg-[#dfdfdf] shadow-md rounded-lg flex flex-col items-center justify-center'>
          <div>
            <img
              src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              alt='image'
              className=' object-cover rounded-full h-24 w-24'
            />
          </div>
          <h3 className=' text-[1.2rem] font-bold'>Jhone Joel</h3>
          <span>Responsable</span>
          <ul className=' mt-1 space-x-1 flex items-center justify-center'>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={'seller.facebook'}
              className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
            >
              <svg
                className='h-3 text-white'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 320 512'
                fill='#FFFFFF'
              >
                <path d='M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z' />
              </svg>
            </Link>
            <Link
              to={'seller.instagram'}
              target='_blank'
              rel='noopener noreferrer'
              className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#ea0b86]'
            >
              <svg
                className='h-3 text-white'
                fill='#FFFFFF'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z' />
              </svg>
            </Link>
            <Link
              to={'seller.linkedin'}
              target='_blank'
              rel='noopener noreferrer'
              className=' rounded-full w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
            >
              <svg
                className='h-3 text-white'
                fill='#FFFFFF'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z' />
              </svg>
            </Link>
          </ul>
        </div>
        <div className=' p-5 bg-[#dfdfdf] shadow-md rounded-lg flex flex-col items-center justify-center'>
          <div>
            <img
              src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              alt='image'
              className=' object-cover rounded-full h-24 w-24'
            />
          </div>
          <h3 className=' text-[1.2rem] font-bold'>Jhone Joel</h3>
          <span>Responsable</span>
          <ul className=' mt-1 space-x-1 flex items-center justify-center'>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={'seller.facebook'}
              className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
            >
              <svg
                className='h-3 text-white'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 320 512'
                fill='#FFFFFF'
              >
                <path d='M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z' />
              </svg>
            </Link>
            <Link
              to={'seller.instagram'}
              target='_blank'
              rel='noopener noreferrer'
              className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#ea0b86]'
            >
              <svg
                className='h-3 text-white'
                fill='#FFFFFF'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z' />
              </svg>
            </Link>
            <Link
              to={'seller.linkedin'}
              target='_blank'
              rel='noopener noreferrer'
              className=' rounded-full w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
            >
              <svg
                className='h-3 text-white'
                fill='#FFFFFF'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z' />
              </svg>
            </Link>
          </ul>
        </div>
        <div className=' p-5 bg-[#dfdfdf] shadow-md rounded-lg flex flex-col items-center justify-center'>
          <div>
            <img
              src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              alt='image'
              className=' rounded-full h-24 w-24'
            />
          </div>
          <h3 className=' text-[1.2rem] font-bold'>Jhone Joel</h3>
          <span>Responsable</span>
          <ul className=' mt-1 space-x-1 flex items-center justify-center'>
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={'seller.facebook'}
              className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
            >
              <svg
                className='h-3 text-white'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 320 512'
                fill='#FFFFFF'
              >
                <path d='M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z' />
              </svg>
            </Link>
            <Link
              to={'seller.instagram'}
              target='_blank'
              rel='noopener noreferrer'
              className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#ea0b86]'
            >
              <svg
                className='h-3 text-white'
                fill='#FFFFFF'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z' />
              </svg>
            </Link>
            <Link
              to={'seller.linkedin'}
              target='_blank'
              rel='noopener noreferrer'
              className=' rounded-full w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
            >
              <svg
                className='h-3 text-white'
                fill='#FFFFFF'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z' />
              </svg>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotreEquipe;
