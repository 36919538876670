import React from 'react';
import ServiceContainer from '../companydetails/ServiceContainer';
import { useData } from '../../data/useData';

const Related = ({ services }) => {
  // const { services } = useData();
  return (
    <div>
      <div className=' w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto mt-6'>
        <h2 className=' text-center md:text-start text-[1.1rem] md:text-[1.2rem] font-semibold'>
          Autres services du même entreprise
        </h2>
      </div>

      <ServiceContainer services={services.slice(0, 4)} />
    </div>
  );
};

export default Related;
