import ExpertiseCard from './ExpertiseCard';
import SectionTitle from '../../utilities/SectionTitle';
import { expertiseData } from '../../data/data';

const Expertise = () => {
  return (
    <div className=' w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto py-5 '>
      <div className=' text-center'>
        <SectionTitle text={`Nos domaines d'expertise`} />
      </div>

      <div className='expert-container'>
        {expertiseData.map((item, index) => (
          <ExpertiseCard
            key={index}
            index={index}
            icon={item.icon}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Expertise;
