import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStorage } from '../../hooks/useStorage';
import { useDocument } from '../../hooks/useDocument';
import { useMyContext } from '../../context/UseContext';
import { useFireStore } from '../../hooks/useFireStore';
import ClipLoader from 'react-spinners/ClipLoader';
import { useAuth } from '../../hooks/useAuth';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#1e4ea0',
  // width: '100%',
};

const Layout = ({ children, ordercard, id }) => {
  const { logout } = useAuth();
  const { user, profil } = useMyContext();
  const { updateDocument, response } = useFireStore('users');

  const { document, isPending, error, success } = response;

  const {
    images,
    progress,
    uploadFiles,
    uploadErr,
    deleteFile,
    setUploadErr,
    successfully,
    success: imageSuccess,
  } = useStorage();

  const [isMouseEnter, setIsMouseEnter] = useState('');
  const [profilUrl, setProfilUrl] = useState('');
  const location = useLocation();

  const handleUpload = (event) => {
    uploadFiles(event.target.files, user);
  };

  useEffect(() => {
    if (imageSuccess) {
      const updateProfil = async () => {
        await updateDocument(user.uid, { ...profil, photo: images });
        setProfilUrl(images);
      };

      updateProfil();
    }
  }, [imageSuccess]);

  useEffect(() => {
    setProfilUrl(profil.photo);
  }, []);

  return (
    <main className=' pb-3'>
      <div
        className={`${ordercard}  w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto   grid grid-cols-5 md:grid-cols-6 lg:grid-cols-5 gap-4 mt-8`}
      >
        <div className={` col-span-5 md:col-span-2 lg:col-span-1`}>
          <div className='bg-white shadow-sm rounded-md '>
            <div>
              <div className=' flex flex-col items-center justify-center p-5'>
                <div className=' h-20 w-20  mb-2 relative'>
                  <label className='absolute top-0 right-0' htmlFor='files'>
                    <input
                      style={{ display: 'none' }}
                      multiple
                      type='file'
                      id='files'
                      onChange={handleUpload}
                    />
                    <div className=' relative cursor-pointer w-8 h-8  bg-white p-2 rounded-full'>
                      <svg
                        className=' h-4 w-4'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                      >
                        <path d='M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z' />
                      </svg>
                    </div>
                  </label>
                  {progress > 0 && (
                    <div className=' absolute flex w-full items-center justify-center h-full top-0'>
                      <ClipLoader
                        color='#1e4ea0'
                        loading={true}
                        cssOverride={override}
                        size={50}
                        data-testid='loader'
                      />
                    </div>
                  )}
                  {profilUrl ? (
                    <img
                      src={profilUrl}
                      alt=''
                      className=' h-full w-full object-cover rounded-full'
                    />
                  ) : (
                    <div className=' rounded-full  flex items-center justify-center overflow-hidden  h-full w-full bg-[#bbbbbb]  object-cover'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                        className=' h-10   w-10'
                        fill='#7c7c7c'
                      >
                        <path d='M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' />
                      </svg>
                    </div>
                  )}
                </div>
                <Link
                  to={'/detail-entreprise/' + profil.id}
                  className=' font-semibold'
                >
                  {profil?.userName}
                </Link>
                {profil.companyType && (
                  <p className=' opacity-65'>{profil.companyType} </p>
                )}
              </div>
            </div>
            <ul>
              {((profil && profil.isSeller) || (profil && profil.isAdmin)) && (
                <li
                  onMouseEnter={() => setIsMouseEnter('admin')}
                  onMouseLeave={() => setIsMouseEnter('')}
                  className={`${
                    location.pathname === '/admin' && 'bg-[#114cb3] text-white'
                  } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                    isMouseEnter === 'admin'
                      ? 'border-t-white'
                      : ' border-t-[#b5b5b5]'
                  }  `}
                >
                  <Link to={'/admin'} className=' flex items-center space-x-2'>
                    <span>
                      <svg
                        className=' h-4'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 384 512'
                        fill={`${
                          location.pathname === '/admin' ||
                          isMouseEnter === 'admin'
                            ? '#ffff'
                            : '#114cb3'
                        } `}
                      >
                        <path d='M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z' />
                      </svg>
                    </span>
                    <span
                      className={`${
                        location.pathname === '/admin' ? ' text-white' : ''
                      }  `}
                    >
                      Tableau de bord
                    </span>
                  </Link>
                </li>
              )}

              <li
                onMouseEnter={() => setIsMouseEnter('profil')}
                onMouseLeave={() => setIsMouseEnter('')}
                className={`${
                  location.pathname === '/profil/edit' &&
                  'bg-[#114cb3] text-white'
                } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                  isMouseEnter === 'profil'
                    ? 'border-t-white'
                    : ' border-t-gray-400'
                }  `}
              >
                <Link
                  to={'/profil/edit'}
                  className=' flex items-center space-x-2'
                >
                  <span>
                    <svg
                      fill={`${
                        location.pathname === '/profil/edit' ||
                        isMouseEnter === 'profil'
                          ? '#ffff'
                          : '#114cb3'
                      } `}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 448 512'
                      className=' h-4'
                    >
                      <path d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z' />
                    </svg>
                  </span>
                  <span>Profil</span>
                </Link>
              </li>
              {((profil && profil.isSeller) || (profil && profil.isAdmin)) && (
                <li
                  onMouseEnter={() => setIsMouseEnter('services')}
                  onMouseLeave={() => setIsMouseEnter('')}
                  className={`${
                    (location.pathname === '/services' ||
                      location.pathname === '/nouveau/service' ||
                      location.pathname === `/edict/service/${id}`) &&
                    'bg-[#114cb3] text-white'
                  } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                    isMouseEnter === 'services'
                      ? 'border-t-white'
                      : ' border-t-[#b5b5b5]'
                  }  `}
                >
                  <Link
                    to={'/services'}
                    className=' flex items-center space-x-2'
                  >
                    <span>
                      <svg
                        fill={`${
                          location.pathname === '/services' ||
                          location.pathname === '/nouveau/service' ||
                          location.pathname === `/edict/service/${id}` ||
                          isMouseEnter === 'services'
                            ? '#ffff'
                            : '#114cb3'
                        } `}
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                        className=' h-4'
                      >
                        <path d='M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48V352h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16V352c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V128H16zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128V352c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z' />
                      </svg>
                    </span>
                    <span>Services</span>
                  </Link>
                </li>
              )}

              <li
                onMouseEnter={() => setIsMouseEnter('mes-commandes')}
                onMouseLeave={() => setIsMouseEnter('')}
                className={`${
                  location.pathname === '/mes-commandes' &&
                  'bg-[#114cb3] text-white'
                } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                  isMouseEnter === 'mes-commandes'
                    ? 'border-t-white'
                    : ' border-t-[#b5b5b5]'
                }  `}
              >
                <Link
                  to={'/mes-commandes'}
                  className=' flex items-center space-x-2'
                >
                  <span>
                    <svg
                      fill={`${
                        location.pathname === '/mes-commandes' ||
                        isMouseEnter === 'mes-commandes'
                          ? '#ffff'
                          : '#114cb3'
                      } `}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 448 512'
                      className=' h-4'
                    >
                      <path d='M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM252 160c0 11 9 20 20 20h44v44c0 11 9 20 20 20s20-9 20-20V180h44c11 0 20-9 20-20s-9-20-20-20H356V96c0-11-9-20-20-20s-20 9-20 20v44H272c-11 0-20 9-20 20z' />
                    </svg>
                  </span>
                  <span>Mes Commandes</span>
                </Link>
              </li>

              {((profil && profil.isSeller) || (profil && profil.isAdmin)) && (
                <li
                  onMouseEnter={() => setIsMouseEnter('commandes')}
                  onMouseLeave={() => setIsMouseEnter('')}
                  className={`${
                    location.pathname === '/commandes' &&
                    'bg-[#114cb3] text-white'
                  } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                    isMouseEnter === 'service'
                      ? 'border-t-white'
                      : ' border-t-[#b5b5b5]'
                  }  `}
                >
                  <Link
                    to={'/commandes'}
                    className=' flex items-center space-x-2'
                  >
                    <span>
                      <svg
                        fill={`${
                          location.pathname === '/commandes' ||
                          isMouseEnter === 'commandes'
                            ? '#ffff'
                            : '#114cb3'
                        } `}
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                        className=' h-4'
                      >
                        <path d='M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z' />
                      </svg>
                    </span>
                    <span>Commandes</span>
                  </Link>
                </li>
              )}

              {profil && profil.isAdmin && (
                <>
                  <li
                    onMouseEnter={() => setIsMouseEnter('annonces')}
                    onMouseLeave={() => setIsMouseEnter('')}
                    className={`${
                      (location.pathname === '/admin/annonces' ||
                        location.pathname === '/nouvvelle-annonces') &&
                      'bg-[#114cb3] text-white'
                    } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                      isMouseEnter === 'annonces'
                        ? 'border-t-white'
                        : ' border-t-[#b5b5b5]'
                    }  `}
                  >
                    <Link
                      to={'/admin/annonces'}
                      className=' flex items-center space-x-2'
                    >
                      <span>
                        <svg
                          fill={`${
                            location.pathname === '/admin/annonces' ||
                            location.pathname === '/nouvvelle-annonces' ||
                            isMouseEnter === 'annonces'
                              ? '#ffff'
                              : '#114cb3'
                          } `}
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'
                          className=' h-4'
                        >
                          <path d='M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z' />
                        </svg>
                      </span>
                      <span>Annonces</span>
                    </Link>
                  </li>
                  <li
                    onMouseEnter={() => setIsMouseEnter('utilisateurs')}
                    onMouseLeave={() => setIsMouseEnter('')}
                    className={`${
                      location.pathname === '/utilisateurs/list' &&
                      'bg-[#114cb3] text-white'
                    } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                      isMouseEnter === 'utilisateurs'
                        ? 'border-t-white'
                        : ' border-t-[#b5b5b5]'
                    }  `}
                  >
                    <Link
                      to={'/utilisateurs/list'}
                      className=' flex items-center space-x-2'
                    >
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          className={`w-5 h-5  ${
                            location.pathname === '/utilisateurs/list' ||
                            isMouseEnter === 'utilisateurs'
                              ? 'text-white'
                              : ' text-[#114cb3] '
                          }`}
                        >
                          <path d='M7 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14.5 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM1.615 16.428a1.224 1.224 0 0 1-.569-1.175 6.002 6.002 0 0 1 11.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 0 1 7 18a9.953 9.953 0 0 1-5.385-1.572ZM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 0 0-1.588-3.755 4.502 4.502 0 0 1 5.874 2.636.818.818 0 0 1-.36.98A7.465 7.465 0 0 1 14.5 16Z' />
                        </svg>
                      </span>
                      <span>Utilisateurs</span>
                    </Link>
                  </li>
                </>
              )}

              <li
                onMouseEnter={() => setIsMouseEnter('deconnexion')}
                onMouseLeave={() => setIsMouseEnter('')}
                className={`${
                  location.pathname === '/ajouter/service' && 'bg-[#114cb3]'
                } px-4 py-2 hover:bg-[#114cb3] hover:text-white border-t-2 ${
                  isMouseEnter === 'service'
                    ? 'border-t-white'
                    : ' border-t-[#b5b5b5]'
                }  `}
              >
                <button
                  onClick={() => logout()}
                  className=' cursor-pointer flex items-center space-x-2'
                >
                  <span>
                    <svg
                      fill={`${
                        isMouseEnter === 'deconnexion' ? '#ffff' : '#114cb3'
                      } `}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 448 512'
                      className=' h-4'
                    >
                      <path d='M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z' />
                    </svg>
                  </span>
                  <span> Déconnexion</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-span-5 md:col-span-4 lg:col-span-4 '>
          {children}
        </div>
      </div>
    </main>
  );
};

export default Layout;
