import React, { useEffect } from 'react';
import Layout from '../components/admin/Layout';
import { useDocument } from '../hooks/useDocument';
import { useParams } from 'react-router-dom';
import { priceFormatter } from '../utils/priceFomatter';
import Loader from '../utilities/utils-components/Loader';
import { useFireStore } from '../hooks/useFireStore';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';

const DetailsCommande = () => {
  const { id } = useParams();
  const { docResponse } = useDocument('orders', id);
  const { updateDocument, response } = useFireStore('orders');

  const deliveryTimeFormatter = (str) => {
    if (str) {
      const matches = str.match(/\d+/g);

      if (matches && matches.length >= 2) {
        const currentDate = new Date();
        const deliveryDays = parseInt(matches[1]);

        currentDate.setDate(currentDate.getDate() + deliveryDays);

        const dayOfWeek = [
          'dimanche',
          'lundi',
          'mardi',
          'mercredi',
          'jeudi',
          'vendredi',
          'samedi',
        ];
        const months = [
          'janvier',
          'février',
          'mars',
          'avril',
          'mai',
          'juin',
          'juillet',
          'août',
          'septembre',
          'octobre',
          'novembre',
          'décembre',
        ];

        const formattedDate = `${
          dayOfWeek[currentDate.getDay()]
        } ${currentDate.getDate()} ${
          months[currentDate.getMonth()]
        } ${currentDate.getFullYear()}`;

        return formattedDate;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const odersTimeFormatter = (str) => {
    if (str) {
      const matches = str.match(/\d+/g);

      if (matches && matches.length >= 2) {
        const currentDate = new Date();

        currentDate.setDate(currentDate.getDate());

        const dayOfWeek = [
          'dimanche',
          'lundi',
          'mardi',
          'mercredi',
          'jeudi',
          'vendredi',
          'samedi',
        ];
        const months = [
          'janvier',
          'février',
          'mars',
          'avril',
          'mai',
          'juin',
          'juillet',
          'août',
          'septembre',
          'octobre',
          'novembre',
          'décembre',
        ];

        const formattedDate = `${
          dayOfWeek[currentDate.getDay()]
        } ${currentDate.getDate()} ${
          months[currentDate.getMonth()]
        } ${currentDate.getFullYear()}`;

        return formattedDate;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const deliveryHandler = async () => {
    await updateDocument(id, { ...docResponse.document, isDelivered: true });
  };

  useEffect(() => {
    if (response.error) {
      toast.error('Erreur de mise a jour ', {
        position: 'top-center',
      });
    }
  }, [response.error]);

  useEffect(() => {
    if (response.success) {
      toast.success('produit marqué comme livré!', {
        position: 'top-center',
      });
    }
  }, [response.success]);

  return (
    <Layout>
      {docResponse.isPending ? (
        <Loader />
      ) : (
        <>
          <div className='grid grid-cols-3 gap-5 '>
            <div className=' col-span-1'>
              <img
                src={docResponse.document?.productImage}
                alt=''
                className=' h-[300px] object-cover w-full rounded-md'
              />
            </div>
            <div className=' col-span-2 relative'>
              {docResponse.document?.isDelivered ? (
                ''
              ) : (
                <div className=' absolute right-0'>
                  <button
                    onClick={deliveryHandler}
                    className=' bg-lime-600 px-4 py-2 text-white rounded-sm flex items-center space-x-1'
                  >
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          fillRule='evenodd'
                          d='M16.403 12.652a3 3 0 0 0 0-5.304 3 3 0 0 0-3.75-3.751 3 3 0 0 0-5.305 0 3 3 0 0 0-3.751 3.75 3 3 0 0 0 0 5.305 3 3 0 0 0 3.75 3.751 3 3 0 0 0 5.305 0 3 3 0 0 0 3.751-3.75Zm-2.546-4.46a.75.75 0 0 0-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </span>
                    <span>
                      {response.isPending ? (
                        <ClipLoader color='#36d7b7' />
                      ) : (
                        'Marqué comme livré'
                      )}
                    </span>
                  </button>
                </div>
              )}

              <h2 className=' font-semibold mb-3 text-[1rem]'>
                {docResponse.document?.title}
              </h2>
              <div>
                <div className=' flex items-center space-x-2'>
                  <h3 className=' font-medium text-[1.1rem]'>Prix</h3>
                  <span className='font-medium opacity-70'>
                    {docResponse.document?.amount} FCFA
                  </span>
                </div>
                <div className=' flex items-center space-x-2'>
                  <h3 className=' font-medium text-[1.1rem]'>Quantité</h3>
                  <span className='font-medium opacity-70'>
                    {docResponse.document?.qty}
                  </span>
                </div>
                <div className=' flex items-center space-x-2'>
                  <h3 className=' font-medium text-[1.1rem]'>Livraison</h3>
                  <span className='font-medium opacity-70'>
                    {priceFormatter(Number(docResponse.document?.shippingFee))}{' '}
                    FCFA
                  </span>
                </div>
                <div className=' flex items-center space-x-2'>
                  <h3 className=' font-medium text-[1.1rem]'>Montant</h3>
                  <span className='font-medium opacity-70'>
                    {priceFormatter(Number(docResponse.document?.totalAmout))}{' '}
                    FCFA
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=' mt-2'>
            <h2 className='font-semibold mb-1 text-[1.2rem]'>Livraison</h2>
            <div>
              <div className=' flex items-center space-x-2'>
                <h3 className=' font-medium text-[1.1rem]'>
                  Date de Commande:
                </h3>
                <span className='font-medium opacity-70'>
                  {odersTimeFormatter(docResponse?.document?.deleveryTime)}
                </span>
              </div>
              {docResponse.document && docResponse.document.deleveryTime && (
                <div className=' flex items-center space-x-2'>
                  <h3 className=' font-medium text-[1.1rem]'>
                    Date de livraison:
                  </h3>
                  <span className='font-medium opacity-70'>
                    du {odersTimeFormatter(docResponse?.document?.deleveryTime)}{' '}
                    au{' '}
                    {deliveryTimeFormatter(docResponse?.document?.deleveryTime)}{' '}
                  </span>
                </div>
              )}

              <div className=' flex items-center space-x-2'>
                <h3 className=' font-medium text-[1.1rem]'>Nom & prenom:</h3>
                <span className='font-medium opacity-70'>
                  {docResponse?.document?.shippingAddresse.name
                    ?.charAt(0)
                    .toUpperCase() +
                    docResponse?.document?.shippingAddresse.name?.slice(1)}{' '}
                  {docResponse?.document?.shippingAddresse.lastName
                    ?.charAt(0)
                    .toUpperCase() +
                    docResponse?.document?.shippingAddresse.lastName?.slice(
                      1
                    )}{' '}
                </span>
              </div>
              <div className=' flex items-center space-x-2'>
                <h3 className=' font-medium text-[1.1rem]'>Addresse:</h3>
                <span className='font-medium opacity-70'>
                  {docResponse?.document?.shippingAddresse.address}
                </span>
              </div>
              <div className=' flex items-center space-x-2'>
                <h3 className=' font-medium text-[1.1rem]'>Email:</h3>
                <span className='font-medium opacity-70'>
                  {docResponse?.document?.shippingAddresse.email}
                </span>
              </div>
              <div className=' flex items-center space-x-2'>
                <h3 className=' font-medium text-[1.1rem]'>Pays:</h3>
                <span className='font-medium opacity-70'>
                  {docResponse?.document?.shippingAddresse.country.name}
                </span>
              </div>
              <div className=' flex items-center space-x-2'>
                <h3 className=' font-medium text-[1.1rem]'>Téléphone:</h3>
                <span className='font-medium opacity-70'>
                  {docResponse?.document?.shippingAddresse.phone}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default DetailsCommande;
