import React from 'react';
import { usePages } from './usePages';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Header from '../utilities/Header';
import Footer from '../utilities/Footer';
import { useMyContext } from '../context/UseContext';

const AppRoutes = () => {
  const { user, profil } = useMyContext();
  const { publicPath, adminPath, adminstratorPath } = usePages(user, profil);
  return (
    <BrowserRouter basname='/'>
      <Header />
      <Routes>
        {publicPath.map(({ Component, path, connected, isloging }) => (
          <Route
            key={path}
            path={path}
            element={connected ? <Navigate to='/' replace /> : <Component />}
          />
        ))}

        {adminPath.map(({ component, path, isloging }) => {
          const Component = component;
          return (
            <Route
              key={path}
              element={
                isloging ? <Component /> : <Navigate to='/connexion' replace />
              }
              path={path}
            />
          );
        })}

        {adminstratorPath.map(({ Component, path, isAdmin }) => {
          return (
            <Route
              key={path}
              element={isAdmin ? <Component /> : <Navigate to='/' replace />}
              path={path}
            />
          );
        })}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRoutes;
