import React from 'react';

const PrimaryButton = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className=' hover:bg-[#1e4ea0] bg-[#114cb3] py-2 text-white text-[1rem] md:text-[1.2rem] px-4'
      type='submit'
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
