import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCollection } from '../hooks/useCollection';
import { useFireStore } from '../hooks/useFireStore';
import { toast } from 'react-toastify';
import Loader from '../utilities/utils-components/Loader';
import Pagination from '../utilities/utils-components/Pagination';
import ErreorMessage from '../utilities/utils-components/ErreorMessage';
import NotFound from '../utilities/utils-components/NotFound';

function Annonces() {
  const { documents, loading, error } = useCollection('ads');

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);
  const [activeIndex, setActiveIndex] = useState(0);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const ads = documents
    .filter((articles) => articles.isVedette === false)
    .slice(indexOfFirstProduct, indexOfLastProduct);

  const vedetteAds = documents.filter(
    (articles) => articles.isVedette === true
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (vedetteAds.length === 1) {
      return;
    } else {
      const intervalId = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % vedetteAds.length);
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [vedetteAds.length]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <ErreorMessage message={'Erreur de recuperation des service'} />
      ) : documents.length === 0 ? (
        <div className=' h-[100px] pt-4 w-[60%] mx-auto justify-center items-center  flex  text-center'>
          <NotFound message='Aucune annonce trouvée' />
        </div>
      ) : (
        <main>
          {vedetteAds.length > 0 && (
            <div className=' h-[600px] max-h-[600px] min-h-[500px] relative w-full'>
              <Link
                to={`/detail-entreprise/${vedetteAds[activeIndex]?.seller?.id}`}
              >
                <img
                  src={vedetteAds[activeIndex]?.image[0]}
                  alt=''
                  className=' h-full w-full object-contain'
                />
              </Link>
              <div className='flex absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bottom-0 justify-center mt-4'>
                {Array.from({ length: vedetteAds.length }).map((_, index) => (
                  <div
                    onClick={() => setActiveIndex(index)}
                    key={index}
                    className={`h-5 w-5   rounded-full  mx-2 cursor-pointer ${
                      activeIndex === index ? 'bg-white' : ' bg-gray-400'
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          )}

          <div className=' w-[95%]  xl:w-[65%] mx-auto mt-5'>
            {ads.length > 0 && (
              <Link
                to={`/detail-entreprise/${ads[0]?.seller?.id}`}
                className='grid grid-cols-3 grid-rows-auto'
              >
                <div className=' col-span-3 md:col-span-2'>
                  <div className=' h-[350px] md:h-[400px]'>
                    <img
                      src={ads[0]?.image}
                      alt=''
                      className=' w-full h-full object-cover'
                    />
                  </div>
                </div>
                <div className=' col-span-3 md:col-span-1'>
                  <h1 className=' text-[1.4rem] font-semibold'>
                    {ads[0]?.title}
                  </h1>
                  <p>{ads[0]?.description}</p>
                </div>
              </Link>
            )}

            {ads.length > 1 && (
              <div className='grid annonce  mt-5 '>
                {ads.slice(1, documents.length).map((article, index) => {
                  console.log(article);
                  return (
                    <Link
                      to={`/detail-entreprise/${article?.seller?.id}`}
                      key={index}
                    >
                      <div>
                        <img
                          src={article?.image}
                          alt=''
                          className=' w-full object-cover h-48'
                        />
                      </div>
                      <h1 className=' text-[1.2rem] font-semibold mt-2'>
                        {article?.title}
                      </h1>
                      <p className=' text-start'>{article?.description}</p>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>

          <Pagination
            documents={ads}
            productsPerPage={productsPerPage}
            currentPage={currentPage}
            paginate={paginate}
          />
        </main>
      )}
    </>
  );
}

export default Annonces;
