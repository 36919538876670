import React, { useEffect, useState } from 'react';

import ServiceContainer from '../components/companydetails/ServiceContainer';

import Pagination from '../utilities/utils-components/Pagination';

import { useParams } from 'react-router-dom';
import { useDocEnt } from '../hooks/useDocEnt';
import SellerNetwork from '../utilities/utils-components/SellerNetwork';
import Loader from '../utilities/utils-components/Loader';
import ErreorMessage from '../utilities/utils-components/ErreorMessage';
import { useFireStore } from '../hooks/useFireStore';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';

const CompanyDetail = () => {
  const { updateDocument, response } = useFireStore('users');
  const params = useParams();
  const { id } = params;

  const { docResponse } = useDocEnt('users', 'products', id);

  const { company, document, isPending, error, success } = docResponse;

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = document.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  async function submitForm() {
    const updatedFields = { isSeller: !company.isSeller };

    await updateDocument(id, updatedFields);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (response.success) {
      toast.success('Compte entreprise mise à jour avec success', {
        position: 'top-center',
      });
    }
  }, [response.success]);
  return (
    <>
      {isPending ? (
        <Loader />
      ) : error ? (
        <ErreorMessage message={'Erreur de recuperation des service'} />
      ) : (
        <main>
          <div className=' w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto'>
            <div className=' bg-[#dfdfdf] relative h-[50vh] md:h-[40vh] rounded-b-md shadow-sm rounded-sm border-2 border-[#68686846]'>
              <div className='  h-[40%] md:h-[65%] relative'>
                {company.isSellerRequested && !company.isSeller && (
                  <div className=' absolute top-5 right-5'>
                    <button
                      onClick={submitForm}
                      className=' hover:bg-green-700 px-6 py-2 rounded-md text-white text-[1.1rem] bg-green-600'
                    >
                      {response.isPending ? (
                        <div className=' flex items-center mr-10'>
                          <ClipLoader size={40} color='#114cb3' />
                        </div>
                      ) : (
                        <span> Approuvé</span>
                      )}
                    </button>
                  </div>
                )}

                {company.isSellerRequested && company.isSeller && (
                  <div className=' absolute top-5 right-5'>
                    <button
                      onClick={submitForm}
                      className=' hover:bg-red-700 px-6 py-2 rounded-md text-white text-[1.1rem] bg-red-600'
                    >
                      {response.isPending ? (
                        <div className=' flex items-center mr-10'>
                          <ClipLoader size={40} color='#114cb3' />
                        </div>
                      ) : (
                        <span>Désapprouvé</span>
                      )}
                    </button>
                  </div>
                )}

                <div className=' absolute lg:hidden block left-[50%] -translate-x-1/2 -translate-y-1/2   top-[70%] h-[180px] w-[250px] '>
                  {company.photo ? (
                    <div className=' border-4 border-white h-[100px] left-10 w-[100px] rounded-full absolute bottom-16'>
                      <img
                        src={company.photo}
                        alt=''
                        className=' h-full w-full object-cover rounded-full'
                      />
                    </div>
                  ) : (
                    <div className=' bg-[#eeeeee]  flex justify-center items-center border-4 border-white h-[180px] left-10 w-[180px] rounded-full absolute bottom-16'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                        className=' h-24 w-24'
                        fill='#727272'
                      >
                        <path d='M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' />
                      </svg>
                    </div>
                  )}
                </div>
                {company.banner ? (
                  <img
                    src={company?.banner[0]}
                    alt=''
                    className='  h-full w-full object-cover rounded-sm'
                  />
                ) : (
                  <div className='  flex items-center justify-center overflow-hidden h-[65%] bg-[#bbbbbb] w-full object-cover rounded-sm'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 512 512'
                      className=' h-60   w-60'
                      fill='#a8a8a8'
                    >
                      <path d='M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' />
                    </svg>
                  </div>
                )}
              </div>

              <div className=' px-2 md:px-0 flex md:flex-row flex-col'>
                <div className=' relative hidden lg:block  h-[180px] w-[250px] '>
                  {company.photo ? (
                    <div className=' border-4 border-white h-[180px] left-10 w-[180px] rounded-full absolute bottom-16'>
                      <img
                        src={company.photo}
                        alt=''
                        className=' h-full w-full object-cover rounded-full'
                      />
                    </div>
                  ) : (
                    <div className=' bg-[#eeeeee]  flex justify-center items-center border-4 border-white h-[180px] left-10 w-[180px] rounded-full absolute bottom-16'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                        className=' h-24 w-24'
                        fill='#727272'
                      >
                        <path d='M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' />
                      </svg>
                    </div>
                  )}
                </div>
                <div className=' flex-1 flex flex-col'>
                  <h2 className='  font-semibold text-[1.1rem] md:text-[1.2rem] mb-2'>
                    {company?.userName}
                  </h2>
                  <div className=' flex items-center space-x-1 mb-1'>
                    {company.address && (
                      <>
                        {' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-5 h-5'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                          />
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z'
                          />
                        </svg>
                        <span className=' text-[0.9rem]  md:text-[1rem]'>
                          {company.address}
                        </span>
                      </>
                    )}
                  </div>
                  <div className=' flex-col md:flex-row flex md:items-center md:space-x-3'>
                    <div className=' flex items-center space-x-1'>
                      {company.phone && (
                        <>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-6 h-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z'
                            />
                          </svg>
                          <span className=' text-[0.9rem]  md:text-[1rem]'>
                            {company.phone}{' '}
                          </span>
                        </>
                      )}
                    </div>
                    <div className=' flex items-center space-x-1'>
                      {company.email && (
                        <>
                          <svg
                            className=' w-5 h-5'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                          >
                            <path d='M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z' />
                          </svg>
                          <span className=' text-[0.9rem]  md:text-[1rem]'>
                            {company.email}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className=' mt-2 mr-2'>
                  <SellerNetwork company={company} />
                </div>
              </div>
            </div>
          </div>
          <ServiceContainer services={currentProducts} />
          <Pagination
            documents={document}
            productsPerPage={productsPerPage}
            currentPage={currentPage}
            paginate={paginate}
          />
        </main>
      )}
    </>
  );
};

export default CompanyDetail;
