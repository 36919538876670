import React, { useState } from 'react';
import Layout from '../components/admin/Layout';
import { useMonthIncom } from '../hooks/useMonthIncom';
import { priceFormatter } from '../utils/priceFomatter';
import ClipLoader from 'react-spinners/ClipLoader';
import { useMyContext } from '../context/UseContext';
import { useCollection } from '../hooks/useCollection';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { user, profil } = useMyContext();
  const { authIsReady } = useMyContext();
  const navigate = useNavigate();
  const [loding, seLoding] = useState(false);

  useEffect(() => {
    const redirectIfNeeded = () => {
      seLoding(true);
      if (profil && !profil.isSeller && !profil.isAdmin) {
        navigate('/profil/edit');
      }
      seLoding(true);
    };

    redirectIfNeeded();
  }, [profil && profil.isSeller, profil && profil.isAdmin]);

  const { docResponse } = useMonthIncom('orders', user.uid);
  const { documents, loading, error } = useCollection(
    'products',
    '',
    '',
    '',
    '',
    user.uid
  );

  const totalSalesPerMonth = docResponse?.document?.reduce((total, product) => {
    return total + product?.totalAmout;
  }, 0);

  const totalSales = docResponse?.orderDoc?.reduce((total, product) => {
    return total + product?.totalAmout;
  }, 0);

  console.log('=========', totalSalesPerMonth);

  return (
    <Layout>
      {loding && (
        <div className=' bg-white shadow-sm rounded-md p-4'>
          <div className=' grid dashboad grid-cols-2 gap-6'>
            <div className=' bg-[#ebebeb] p-3 rounded-md'>
              <div className=' flex items-start space-x-2'>
                <span className=' bg-blue-200 p-2 rounded-md'>
                  <svg
                    className=' h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                    fill='#114cb3'
                  >
                    <path d='M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64H80c-8.8 0-16-7.2-16-16s7.2-16 16-16H448c17.7 0 32-14.3 32-32s-14.3-32-32-32H64zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z' />
                  </svg>
                </span>
                <div className=' flex flex-col'>
                  {docResponse.isPending ? (
                    <div className=' flex items-center mr-10'>
                      <ClipLoader size={40} color='#114cb3' />
                    </div>
                  ) : (
                    <>
                      <span className=' text-[1.1rem] font-semibold opacity-70'>
                        Solde du mois
                      </span>{' '}
                      <h3 className=' font-bold text-base md:text-[1.4rem]'>
                        {totalSalesPerMonth === undefined
                          ? 0
                          : priceFormatter(parseInt(totalSalesPerMonth))}{' '}
                        FCFA
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className=' bg-[#ebebeb] p-3 rounded-md'>
              <div className=' flex items-start space-x-2'>
                <span className=' bg-blue-200 p-2 rounded-md'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='w-5 h-5  text-[#114cb3]'
                  >
                    <path d='M1 4.25a3.733 3.733 0 0 1 2.25-.75h13.5c.844 0 1.623.279 2.25.75A2.25 2.25 0 0 0 16.75 2H3.25A2.25 2.25 0 0 0 1 4.25ZM1 7.25a3.733 3.733 0 0 1 2.25-.75h13.5c.844 0 1.623.279 2.25.75A2.25 2.25 0 0 0 16.75 5H3.25A2.25 2.25 0 0 0 1 7.25ZM7 8a1 1 0 0 1 1 1 2 2 0 1 0 4 0 1 1 0 0 1 1-1h3.75A2.25 2.25 0 0 1 19 10.25v5.5A2.25 2.25 0 0 1 16.75 18H3.25A2.25 2.25 0 0 1 1 15.75v-5.5A2.25 2.25 0 0 1 3.25 8H7Z' />
                  </svg>
                </span>
                <div className=' flex flex-col'>
                  {docResponse.isPending ? (
                    <div className=' flex items-center mr-10'>
                      <ClipLoader size={40} color='#114cb3' />
                    </div>
                  ) : (
                    <>
                      <span className=' text-[1.1rem] font-semibold opacity-70'>
                        Vente total
                      </span>
                      <h3 className=' font-bold text-base md:text-[1.4rem]'>
                        {totalSales === undefined
                          ? 0
                          : priceFormatter(parseInt(totalSales))}{' '}
                        FCFA
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=' grid dashboad gap-6 mt-5'>
            <div className=' bg-[#ebebeb] p-3 rounded-md'>
              <div className=' flex items-start space-x-2'>
                <span className=' bg-blue-200 p-2 rounded-md'>
                  <svg
                    className=' h-5 w-5'
                    fill='#114cb3'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                  >
                    <path d='M96 96c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H80c-44.2 0-80-35.8-80-80V128c0-17.7 14.3-32 32-32s32 14.3 32 32V400c0 8.8 7.2 16 16 16s16-7.2 16-16V96zm64 24v80c0 13.3 10.7 24 24 24H296c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24H184c-13.3 0-24 10.7-24 24zm208-8c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H384c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H384c-8.8 0-16 7.2-16 16zM160 304c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z' />
                  </svg>
                </span>
                <div className=' flex flex-col'>
                  {loading ? (
                    <div className=' flex items-center mr-10'>
                      <ClipLoader size={40} color='#114cb3' />
                    </div>
                  ) : (
                    <>
                      <span className=' text-[1.1rem] font-semibold opacity-70'>
                        Service total
                      </span>{' '}
                      <h3 className=' font-bold text-base md:text-[1.4rem]'>
                        {documents.length}{' '}
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Dashboard;
