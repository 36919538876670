import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { fr } from 'date-fns/locale/fr';
import { getDatesBetween } from '../../utils/getDatesBetween';
registerLocale('fr', fr);

function Datepicker({ orders, isIncludes, setIsIncludes, setBookingDays }) {
  const [startDate, setStartDate] = useState(new Date());
  const [excludeDates, setExcludeDates] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const bookingDays = () => {
      const excludedDates = orders.map((order) => order.bookingDays).flat();

      const excludeDates = excludedDates.filter((date) => date);
      setExcludeDates(excludeDates);
    };

    bookingDays();
  }, []);

  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    const datesBetween = getDatesBetween(start, end);
    setBookingDays(datesBetween);

    const isInclude = excludeDates.some((excludeDate) => {
      return datesBetween.some(
        (date) => date.toDateString() === new Date(excludeDate).toDateString()
      );
    });
    setIsIncludes(isInclude);
  };

  useEffect(() => {
    if (isIncludes) {
      setErrorMessage("La période de réservation n'est pas disponible.");
    } else {
      setErrorMessage(null);
    }
  }, [isIncludes]);
  return (
    <div className=' mt-5 bg-white p-4 rounded-md w-full'>
      {errorMessage && (
        <span className=' text-[0.9rem] md:text-[1.2rem] text-red-500'>
          {errorMessage}{' '}
        </span>
      )}
      <h2 className=' text-[1.1rem] md:text-[1.2rem] font-semibold'>
        Disponibilité
      </h2>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        excludeDates={excludeDates}
        selectsRange
        selectsDisabledDaysInRange
        inline
        locale='fr'
        monthsShown={2}
      />
    </div>
  );
}

export default Datepicker;
