import React, { useState } from 'react';

const Question = ({ faq, index }) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  console.log(currentIndex);
  return (
    <div className=' mt-3'>
      <div className=' flex justify-between items-center '>
        <h3 className=' font-semibold text-[1rem] md:text-[1.1rem]'>
          {faq.question}{' '}
        </h3>
        <div className=' flex items-center'>
          {index === currentIndex ? (
            <button
              onClick={() =>
                setCurrentIndex(currentIndex === index ? null : index)
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-5 h-5 text-[#114cb3]'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='m4.5 15.75 7.5-7.5 7.5 7.5'
                />
              </svg>
            </button>
          ) : (
            <button
              onClick={() =>
                setCurrentIndex(currentIndex === index ? null : index)
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-5 h-5 text-[#114cb3]'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='m19.5 8.25-7.5 7.5-7.5-7.5'
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <p
        className={`${currentIndex === index ? 'block' : 'hidden'} text-[1rem]`}
      >
        {faq.answer}
      </p>
    </div>
  );
};

export default Question;
