import React from 'react';
import PagesHeader from '../utilities/PagesHeader';
import { Link, useNavigate } from 'react-router-dom';
import StartNow from '../utilities/utils-components/StartNow';

const ContactUs = () => {
  const navigate = useNavigate();
  const clikHandler = () => {
    navigate('/faqs');
  };
  return (
    <main>
      <PagesHeader
        images={
          'https://images.unsplash.com/photo-1558402955-dabff43cb9c0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        }
        pageTitle={'Contactez-nous'}
      />
      <div className=' w-[85%] md:w-[95%] lg:w-[80%] mx-auto my-8'>
        <div className=' grid gap-10 grid-cols-1 md:grid-cols-2'>
          <div>
            <h3 className=' font-semibold text-[1.1rem] md:text-[1.3rem]'>
              Information de contact
            </h3>
            <div className=' flex flex-col space-y-2  mt-3'>
              <div className=' flex space-x-2 items-center'>
                <span>
                  <svg
                    className=' h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                  >
                    <path d='M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z' />
                  </svg>
                </span>
                <span className=' text-[1.1rem]'>(+225) 2724582878</span>
              </div>
              <div className=' flex space-x-2 items-center'>
                <span>
                  <svg
                    className=' h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                  >
                    <path d='M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z' />
                  </svg>
                </span>
                <span className=' text-[1.1rem]'>(+225) 0708006975</span>
              </div>
              <div className=' space-x-2 flex items-center'>
                <span>
                  <svg
                    className=' h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                  >
                    <path d='M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z' />
                  </svg>
                </span>
                <span className=' text-[1.1rem]'>
                  administration@pretfog.com
                </span>
              </div>
              <div className=' space-x-2 flex items-center'>
                <svg
                  className=' h-5 w-5'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 384 512'
                >
                  <path d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z' />
                </svg>
                <span className=' text-[1.1rem]'>Cocody II plateaux.</span>
              </div>
            </div>
            <h3 className=' font-semibold text-[1.1rem] md:text-[1.3rem] mt-4'>
              Media Social
            </h3>
            <ul className=' mt-1 space-x-1 flex  items-center'>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                to={'https://www.facebook.com/PRETFOG?mibextid=ZbWKwL'}
                className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
              >
                <svg
                  className='h-3 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 320 512'
                  fill='#FFFFFF'
                >
                  <path d='M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z' />
                </svg>
              </Link>
              <Link
                to={'twitter.com/RAPretfog'}
                target='_blank'
                rel='noopener noreferrer'
                className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#1d9bf0]'
              >
                <svg
                  className='h-3 text-white'
                  fill='#FFFFFF'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                >
                  <path d='M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z' />
                </svg>
              </Link>
              <Link
                to={'seller.linkedin'}
                target='_blank'
                rel='noopener noreferrer'
                className=' rounded-full w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
              >
                <svg
                  className='h-3 text-white'
                  fill='#FFFFFF'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 448 512'
                >
                  <path d='M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z' />
                </svg>
              </Link>
              <Link
                to={'https://youtube.com/@PRETFOGT?si=f-moO0nMjCIFIcmk'}
                target='_blank'
                rel='noopener noreferrer'
                className=' rounded-full w-[35px] h-[35px] flex justify-center items-center bg-[#ff0000]'
              >
                <svg
                  className='h-3 text-white'
                  fill='#FFFFFF'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 576 512'
                >
                  <path d='M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z' />
                </svg>
              </Link>
            </ul>
          </div>
          <div>
            <h3 className=' font-semibold text-[1.1rem] md:text-[1.3rem]'>
              Envoyer votre message
            </h3>
            <form action='' className=' mt-2'>
              <div className=' gap-8 grid grid-cols-2'>
                <div className=' flex flex-col'>
                  <label className=' font-medium' htmlFor=''>
                    Nom:
                  </label>
                  <input
                    className=' outline-none p-2 rounded-sm bg-transparent border-[1px] border-[#afafaf]'
                    type='text'
                    placeholder='Entrer votre nom'
                  />
                </div>
                <div className=' flex flex-col'>
                  <label className=' font-medium' htmlFor=''>
                    Email:
                  </label>
                  <input
                    type='text'
                    className=' outline-none p-2 rounded-sm bg-transparent border-[1px] border-[#afafaf]'
                    placeholder='Entrer votre email'
                  />
                </div>
              </div>
              <div className='flex flex-col mt-5 w-full'>
                <label className=' font-medium' htmlFor=''>
                  Messsage:
                </label>
                <textarea
                  type='text'
                  rows={6}
                  className=' resize-none outline-none p-2 rounded-sm bg-transparent border-[1px] border-[#afafaf]'
                  placeholder='Entrer votre email'
                />
              </div>
              <button className=' bg-[#114cb3] text-white px-4 py-2 mt-2'>
                Envoyer
              </button>
            </form>
          </div>
        </div>
      </div>
      <StartNow
        text={'Avez-vous des questions?'}
        textButton={'FAQ'}
        clikHandler={clikHandler}
      />
    </main>
  );
};

export default ContactUs;
