export function useData() {
  const carousel = [
    'https://images.unsplash.com/photo-1665686308827-eb62e4f6604d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1665686304355-0b09b1e3b03c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1664575197593-2e7085ef086b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  ];
  const services = [
    {
      imageurl:
        'https://images.unsplash.com/photo-1665686307516-1915b9616526?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      contry: 'Allemagne',
      companyname: 'Good fashion',
      rating: '4.5',
      categorie: 'Restaurant',
      description:
        '  Cuisine, repas, gastronomie,service, restauration, convivialité, ambiance, menu, dégustation',
      contrieCode: 'BE',
    },
    {
      imageurl:
        'https://plus.unsplash.com/premium_photo-1677171749355-4ad563d86165?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      contry: 'France',
      companyname: 'Le Petit Bistro',
      rating: '4.8',
      categorie: 'Restaurant',
      description:
        'Bistro français traditionnel avec cuisine maison, vins fins et ambiance chaleureuse.',
      contrieCode: 'FR',
    },
    {
      imageurl:
        'https://images.unsplash.com/photo-1552581234-26160f608093?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      contry: 'Italie',
      companyname: 'La Pizzeria Bella',
      rating: '4.7',
      categorie: 'Restaurant',
      description:
        'Pizzeria authentique proposant une variété de pizzas savoureuses cuites au four à bois.',
      contrieCode: 'IT',
    },
    {
      imageurl:
        'https://images.unsplash.com/photo-1499750310107-5fef28a66643?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      contry: 'Espagne',
      companyname: 'Tapas Bar El Flamenco',
      rating: '4.6',
      categorie: 'Restaurant',
      description:
        'Bar à tapas espagnol offrant une sélection de tapas authentiques et de sangria maison.',
      contrieCode: 'ES',
    },
    {
      imageurl:
        'https://images.unsplash.com/photo-1531497865144-0464ef8fb9a9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      contry: 'Japon',
      companyname: 'Sushi Palace',
      rating: '4.9',
      categorie: 'Restaurant',
      description:
        'Restaurant japonais réputé pour ses sushis frais et ses plats traditionnels.',
      contrieCode: 'JP',
    },
    {
      imageurl:
        'https://images.unsplash.com/photo-1531482615713-2afd69097998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      contry: 'États-Unis',
      companyname: 'The Steakhouse',
      rating: '4.8',
      categorie: 'Restaurant',
      description:
        'Steakhouse de première classe offrant des coupes de viande de qualité supérieure et une expérience gastronomique raffinée.',
      contrieCode: 'US',
    },
    {
      imageurl:
        'https://images.unsplash.com/photo-1542744095-fcf48d80b0fd?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      contry: "Côte d'ivoire",
      companyname: 'Fish & Chips Emporium',
      rating: '4.7',
      categorie: 'Restaurant',
      description:
        'Restaurant britannique traditionnel spécialisé dans le poisson et les frites fraîchement préparés.',
      contrieCode: 'CI',
    },
    {
      imageurl:
        'https://images.unsplash.com/photo-1505330622279-bf7d7fc918f4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      contry: 'Inde',
      companyname: 'Taj Mahal Restaurant',
      rating: '4.7',
      categorie: 'Restaurant',
      description:
        'Restaurant indien offrant une cuisine épicée et savoureuse, des plats tandoori et des spécialités végétariennes.',
      contrieCode: 'IN',
    },
    {
      imageurl:
        'https://images.unsplash.com/photo-1598516754161-0c7450d9f02c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
      contry: 'Chine',
      companyname: 'Palais de Pékin',
      rating: '4.6',
      categorie: 'Restaurant',
      description:
        'Restaurant chinois réputé pour ses plats authentiques, ses dim sum et ses spécialités de fruits de mer.',
      contrieCode: 'CN',
    },
  ];

  const faqs = [
    {
      question: 'Quels sont les services proposés par votre plateforme ?',
      answer:
        "Notre plateforme propose une variété de services tels que la recherche d'entreprises, la comparaison de produits, la consultation des avis clients, etc.",
    },
    {
      question: "Comment puis-je m'inscrire sur votre site ?",
      answer:
        "Pour vous inscrire sur notre site, vous devez cliquer sur le bouton 'Inscription' situé en haut à droite de la page d'accueil et remplir le formulaire d'inscription.",
    },
    {
      question:
        'Est-ce que je peux ajouter mon entreprise à votre répertoire ?',
      answer:
        "Oui, vous pouvez ajouter votre entreprise à notre répertoire en remplissant le formulaire d'inscription pour les entreprises et en fournissant les informations nécessaires.",
    },
    {
      question:
        'Comment puis-je mettre à jour les informations de mon entreprise ?',
      answer:
        "Pour mettre à jour les informations de votre entreprise, connectez-vous à votre compte, accédez à la section 'Mon profil' et modifiez les détails nécessaires.",
    },
    {
      question:
        'Quels sont les avantages de choisir des entreprises répertoriées sur votre site ?',
      answer:
        'Les entreprises répertoriées sur notre site sont vérifiées et évaluées par notre équipe pour garantir la qualité des services et des produits proposés.',
    },
    {
      question:
        'Comment puis-je contacter le service clientèle en cas de problème ?',
      answer:
        "Vous pouvez contacter notre service clientèle en utilisant le formulaire de contact sur notre site ou en envoyant un e-mail à l'adresse indiquée sur la page de contact.",
    },
    {
      question:
        'Quels sont les modes de paiement acceptés sur votre plateforme ?',
      answer:
        "Nous acceptons les paiements par carte de crédit, PayPal, virement bancaire et d'autres méthodes de paiement sécurisées.",
    },
    {
      question: "Comment puis-je suivre ma commande après l'avoir passée ?",
      answer:
        "Une fois votre commande passée, vous recevrez un e-mail de confirmation contenant un numéro de suivi. Vous pouvez utiliser ce numéro pour suivre l'état de votre commande.",
    },
    {
      question:
        'Offrez-vous des remboursements si je ne suis pas satisfait de mon achat ?',
      answer:
        "Oui, nous proposons une politique de remboursement dans certains cas. Veuillez consulter nos conditions générales de vente pour plus d'informations.",
    },
    {
      question:
        'Comment puis-je filtrer les entreprises en fonction de mes préférences ?',
      answer:
        'Vous pouvez utiliser les filtres disponibles sur notre site pour trier les entreprises par catégorie, localisation, évaluation, etc.',
    },
    {
      question: 'Comment puis-je trouver des entreprises près de chez moi ?',
      answer:
        'Vous pouvez utiliser la fonction de recherche sur notre site en spécifiant votre emplacement pour trouver des entreprises à proximité.',
    },
    {
      question:
        "Quels types d'entreprises sont répertoriés sur votre plateforme ?",
      answer:
        "Notre plateforme répertorie une large gamme d'entreprises, y compris des restaurants, des boutiques, des services professionnels, des entreprises en ligne, etc.",
    },
    {
      question:
        "Est-ce que je peux laisser un avis sur une entreprise que j'ai visitée ?",
      answer:
        'Oui, vous pouvez laisser un avis sur une entreprise après avoir utilisé ses services ou acheté ses produits. Votre avis aidera les autres utilisateurs à prendre des décisions informées.',
    },
    {
      question: 'Y a-t-il des frais pour utiliser votre plateforme ?',
      answer:
        "Non, l'utilisation de notre plateforme est entièrement gratuite pour les utilisateurs. Il n'y a aucun frais caché.",
    },
    {
      question: 'Puis-je réserver des services en ligne à travers votre site ?',
      answer:
        'Oui, certaines entreprises répertoriées sur notre site offrent la possibilité de réserver leurs services en ligne. Vous pouvez vérifier la disponibilité et réserver directement à partir de notre plateforme.',
    },
    {
      question:
        'Comment puis-je signaler une entreprise qui ne respecte pas les normes de qualité ?',
      answer:
        'Si vous rencontrez une entreprise qui ne respecte pas les normes de qualité ou si vous avez des préoccupations, veuillez nous contacter via notre formulaire de signalement et nous prendrons les mesures appropriées.',
    },
    {
      question:
        'Puis-je ajouter des entreprises à ma liste de favoris pour un accès facile ?',
      answer:
        "Oui, vous pouvez ajouter des entreprises à votre liste de favoris en cliquant sur le bouton approprié sur la page de profil de l'entreprise. Cela vous permettra d'y accéder facilement à l'avenir.",
    },
    {
      question:
        'Est-ce que je peux modifier ou supprimer mon avis sur une entreprise ?',
      answer:
        "Oui, vous pouvez modifier ou supprimer votre avis sur une entreprise à tout moment en accédant à la section 'Mes avis' de votre compte.",
    },
    {
      question: 'Comment puis-je signaler un problème technique sur le site ?',
      answer:
        'Si vous rencontrez un problème technique sur notre site, veuillez nous contacter via notre formulaire de support technique et nous ferons de notre mieux pour résoudre le problème rapidement.',
    },
    {
      question:
        'Puis-je contacter directement les entreprises répertoriées sur votre plateforme ?',
      answer:
        'Oui, vous pouvez trouver les coordonnées des entreprises sur leur page de profil et les contacter directement par téléphone, e-mail ou formulaire de contact.',
    },
  ];
  return { carousel, services, faqs };
}
