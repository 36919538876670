import { eachDayOfInterval } from 'date-fns';

export const getDatesBetween = (startDate, endDate) => {
  const dates = eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });

  return dates;
};
