import { useEffect, useReducer, useState } from 'react';
import { useMyContext } from '../context/UseContext';
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from 'firebase/auth';
import { auth, db } from '../firebase/config';
import { doc, setDoc } from 'firebase/firestore';

const initialSate = {
  user: null,
  isloading: false,
  error: null,
  success: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'IS_LOADING':
      return { ...state, isloading: true };
    case 'COMPLETE':
      return {
        isloading: false,
        error: null,
        user: action.payload,
        success: true,
      };

    case 'ERROR':
      return {
        ...state,
        user: null,
        isloading: false,
        error: action.payload,
        success: false,
      };

    case 'RESET':
      return { user: null, isloading: false, error: null, success: null };
    default:
      return state;
  }
};

export const useAuth = () => {
  const [authState, authDispach] = useReducer(authReducer, initialSate);
  const [isCancelled, setIsCancelled] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const { dispatch } = useMyContext();

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      authDispach(action);
    }
  };

  const signin = (email, password) => {
    authDispach({ type: 'IS_LOADING' });
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: 'LOGIN', payload: res.user });
        dispatchIfNotCancelled({ type: 'COMPLETE', payload: res.user });
      })
      .catch((err) => {
        console.log(err.code);

        switch (err.code) {
          case 'auth/invalid-credential':
            dispatchIfNotCancelled({
              type: 'ERROR',
              payload: `E-mail ou mot de passe invalide.`,
            });
            break;

          case 'auth/user-not-found':
            dispatchIfNotCancelled({
              type: 'ERROR',
              payload: `L'utilisateur avec cet e-mail n'a pas été trouvé.`,
            });
            break;

          case 'auth/wrong-password':
            dispatchIfNotCancelled({
              type: 'ERROR',
              payload: `Le mot de passe est incorrect.`,
            });
            break;

          case 'auth/network-request-failed':
            dispatchIfNotCancelled({
              type: 'ERROR',
              payload: `Échec de la connexion réseau. Veuillez vérifier votre connexion Internet.`,
            });
            break;

          case 'auth/user-disabled':
            dispatchIfNotCancelled({
              type: 'ERROR',
              payload: `Ce compte utilisateur a été désactivé.`,
            });
            break;

          default:
            dispatchIfNotCancelled({
              type: 'ERROR',
              payload: `Une erreur est survenue lors de la connexion. Veuillez réessayer plus tard.`,
            });
        }
      });
  };

  const signup = async (email, password, displayName) => {
    authDispach({ type: 'IS_LOADING' });
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(res.user, { displayName });

      const userDocRef = doc(db, 'users', res.user.uid);
      await setDoc(userDocRef, {
        userName: displayName,
        phone: '',
        email,
        photo: '',
        address: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        banner: [],
        isAdmin: false,
        isSeller: false,
        isSellerRequested: false,
        contrieCode: '',
        star: 0,
        createdAt: Date.now(),
        comments: [],
      });

      dispatchIfNotCancelled({ type: 'COMPLETE', payload: res.user });
      dispatch({ type: 'LOGIN', payload: res.user });
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          dispatchIfNotCancelled({
            type: 'ERROR',
            payload: 'Adresse e-mail déjà utilisée.',
          });
          setTimeout(() => {
            dispatchIfNotCancelled({
              type: 'RESET',
            });
          }, 3000);
          break;
        case 'auth/invalid-email':
          dispatchIfNotCancelled({
            type: 'ERROR',
            payload: 'Adresse e-mail non valide.',
          });
          setTimeout(() => {
            dispatchIfNotCancelled({
              type: 'RESET',
            });
          }, 3000);
          break;
        case 'auth/weak-password':
          dispatchIfNotCancelled({
            type: 'ERROR',
            payload: 'Mot de passe trop faible.',
          });
          setTimeout(() => {
            dispatchIfNotCancelled({
              type: 'RESET',
            });
          }, 3000);
          break;
        default:
          dispatchIfNotCancelled({
            type: 'ERROR',
            payload: "Erreur lors de l'inscription :",
          });
          setTimeout(() => {
            dispatchIfNotCancelled({
              type: 'RESET',
            });
          }, 3000);
      }
    }
  };

  const logout = () => {
    authDispach({ type: 'IS_LOADING' });
    signOut(auth)
      .then(() => {
        dispatchIfNotCancelled({ type: 'COMPLETE', payload: null });
        dispatch({ type: 'LOGOUT' });
      })
      .catch((err) => {
        console.log(err);
        dispatchIfNotCancelled({
          type: 'ERROR',
          payload: `Erreur de déconnexion`,
        });
      });
  };

  const forgotPassword = (email) => {
    setLoading(true);
    setMessage('');

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage(
          'Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.'
        );
        setError('');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(
          "Erreur lors de l'envoi de l'e-mail. Veuillez vérifier l'adresse e-mail."
        );
        setMessage('');
        setLoading(false);
      });
  };

  const resetPassword = (password, confirmPassword, oobCode) => {
    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }

    if (!oobCode) {
      setError('Lien de réinitialisation invalide.');
      return;
    }

    setLoading(true);
    setMessage('');
    setSuccess(false);
    setError('');

    confirmPasswordReset(auth, oobCode, password)
      .then(() => {
        setMessage('Mot de passe réinitialisé avec succès.');
        setSuccess(true);
        setError('');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError('Erreur lors de la réinitialisation du mot de passe.');
        setLoading(false);
      });
  };

  useEffect(() => {
    setIsCancelled(false);
    return () => setIsCancelled(true);
  }, []);

  return {
    authState,
    signin,
    signup,
    logout,
    dispatchIfNotCancelled,
    forgotPassword,
    message,
    loading,
    error,
    resetPassword,
    success,
  };
};
