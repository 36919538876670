import React from 'react';

const Pagination = ({ documents, productsPerPage, currentPage, paginate }) => {
  return (
    <div className=''>
      <ul className=' flex items-center justify-center mt-2 space-x-1 '>
        {Array.from({
          length: Math.ceil(documents.length / productsPerPage),
        }).map((_, index) => (
          <button
            className={`rounded-sm border-gray-400 border-2 px-3 py-1  ${
              index + 1 === currentPage ? 'bg-[#d60e0e] text-white' : ''
            } `}
            key={index}
            onClick={() => paginate(index + 1)}
          >
            {index + 1}
          </button>
        ))}

        {/* <li className=' cursor-pointer  '>
          <span>1</span>
        </li>
        <li className=' cursor-pointer rounded-sm border-gray-400 border-2 px-3 py-1'>
          <span>2</span>
        </li>
        <li className=' cursor-pointer rounded-sm border-gray-400 border-2 px-3 py-1'>
          <span>3</span>
        </li>
        <li className=' cursor-pointer rounded-sm border-gray-400 border-2 px-3 py-1'>
          <span>4</span>
        </li> */}
      </ul>
    </div>
  );
};

export default Pagination;
