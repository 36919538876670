import React from 'react';

const Header = ({ service }) => {
  return (
    <div className=' h-[5rem] md:h-[10rem] relative'>
      <div className=' h-full w-full absolute z-20 bg-[#0000006f]'></div>
      <img
        src={service.gallery[0]}
        alt='images'
        className=' h-full w-full object-cover'
      />
    </div>
  );
};

export default Header;
