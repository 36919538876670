import React from 'react';
import { useState } from 'react';

import SelectInput from '../../utilities/utils-components/SelectInput';
import { companyTypes, countries } from '../../data/data';

export const CompanyHeader = ({
  setCountrieSelected,
  countrieSelected,
  setTypes,
  types,
  submitSearch,
  setSearch,
}) => {
  return (
    <div className=' h-[50vh] lg:h-[40vh] relative'>
      <div className=' z-20 bg-[#00000065] w-full h-full top-0 bottom-0 right-0 left-0 absolute'></div>
      <div className=' h-fullb absolute top-0 right-0 left-0 bottom-0 z-0'>
        <img
          src='https://images.unsplash.com/photo-1459749411175-04bf5292ceea?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          alt=''
          className=' h-full w-full object-cover object-top '
        />
      </div>
      <div className='w-[95%] xl:w-[90%] 2xl:w-[60%] z-50   absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 '>
        <h1 className=' text-center text-[1rem] md:text-[1.5rem]  lg:text-[2rem] font-bold text-white uppercase'>
          Trouvez les meilleurs services locaux
        </h1>
        <form
          onSubmit={submitSearch}
          className=' flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-3 mt-4 lg:mt-8'
        >
          <div className='flex-1 border-gray-400 border-b-2 '>
            <input
              onChange={(e) => setSearch(e.target.value)}
              className=' px-3 lg:px-0 w-full bg-transparent outline-none text-white'
              type='text'
              placeholder={`Nom de l'entreprise`}
            />
          </div>
          <div className='flex-1 border-gray-400 z-40 border-b-2 '>
            <SelectInput
              inputEls={companyTypes}
              setSelected={setTypes}
              selected={types}
              isWhite={true}
              iconColor='text-white'
            />
          </div>

          <div className='flex-1 border-gray-400 border-b-2 '>
            <SelectInput
              inputEls={countries}
              setSelected={setCountrieSelected}
              selected={countrieSelected}
              isWhite={true}
              iconColor='text-white'
            />
          </div>
          <button className=' bg-[#d60e0e] text-white py-2 lg:py-2 px-5  font-medium'>
            Trouver une entreprise
          </button>
        </form>
      </div>
    </div>
  );
};
