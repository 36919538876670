import {
  faChartBar,
  faHandshake,
  faLaptop,
  faProjectDiagram,
  faShoppingCart,
  faTools,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const countries = [
  { name: 'Toutes les localités' },
  { name: "Côte d'Ivoire", code: 'CI' },
  { name: 'Sénégal', code: 'SN' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'France', code: 'FR' },
  { name: 'Belgique', code: 'BE' },
  { name: 'Suisse', code: 'CH' },
  { name: 'Canada', code: 'CA' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Haïti', code: 'HT' },
  { name: 'République Démocratique du Congo', code: 'CD' },
  { name: 'République du Congo', code: 'CG' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Mali', code: 'ML' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Niger', code: 'NE' },
  { name: 'Togo', code: 'TG' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Bénin', code: 'BJ' },
  { name: 'Centrafrique', code: 'CF' },
  { name: 'Comores', code: 'KM' },
  { name: 'Congo', code: 'CD' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Guinée', code: 'GN' },
  { name: 'Guinée-Bissau', code: 'GW' },
  { name: 'Laos', code: 'LA' },
  { name: 'Liban', code: 'LB' },
  { name: 'Maurice', code: 'MU' },
  { name: 'Mauritanie', code: 'MR' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Tchad', code: 'TD' },
  { name: 'Tunisie', code: 'TN' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Cambodge', code: 'KH' },
  { name: 'La Réunion', code: 'RE' },
  { name: 'Chine', code: 'CN' },
  { name: 'Canada', code: 'CA' },
  { name: 'Etats unis', code: 'US' },
];

export const pays = [
  { name: 'Pays' },
  { name: "Côte d'Ivoire", code: 'CI' },
  { name: 'Sénégal', code: 'SN' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'France', code: 'FR' },
  { name: 'Belgique', code: 'BE' },
  { name: 'Suisse', code: 'CH' },
  { name: 'Canada', code: 'CA' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Haïti', code: 'HT' },
  { name: 'République Démocratique du Congo', code: 'CD' },
  { name: 'République du Congo', code: 'CG' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Mali', code: 'ML' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Niger', code: 'NE' },
  { name: 'Togo', code: 'TG' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Bénin', code: 'BJ' },
  { name: 'Centrafrique', code: 'CF' },
  { name: 'Comores', code: 'KM' },
  { name: 'Congo', code: 'CD' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Guinée', code: 'GN' },
  { name: 'Guinée-Bissau', code: 'GW' },
  { name: 'Laos', code: 'LA' },
  { name: 'Liban', code: 'LB' },
  { name: 'Maurice', code: 'MU' },
  { name: 'Mauritanie', code: 'MR' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Tchad', code: 'TD' },
  { name: 'Tunisie', code: 'TN' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Cambodge', code: 'KH' },
  { name: 'La Réunion', code: 'RE' },
  { name: 'Chine', code: 'CN' },
  { name: 'Canada', code: 'CA' },
  { name: 'Etats unis', code: 'US' },
];

export const companyTypes = [
  { name: "Types d'entreprises" },
  // { name: 'Maçon' },
  // { name: 'Menuisier' },
  { name: 'Métier' },
  { name: 'Restaurant' },
  { name: 'Hôtel' },
  { name: 'Bar' },
  { name: 'Maquis' },
  { name: 'Agence de voyages' },
  // { name: 'Carrelage' },
  { name: 'Location & vente de véhicule' },
  { name: 'Vente de vivrier' },
  { name: 'Électroménagère' },
  { name: 'Vêtement et marque' },
  { name: 'Électronique' },
  { name: 'Informatique' },
  { name: 'Appareil France au revoir' },
  { name: 'Autres' },
];

export const domaineTypes = [
  { name: 'Prestation de service' },
  { name: 'Commerce' },
];

export const commercial = [
  { name: 'Vente de vivrier' },
  { name: 'Électroménagère' },
  { name: 'Vêtement et marque' },
  { name: 'Électronique' },
  { name: 'Informatique' },
  { name: 'Appareil France au revoir' },
  { name: 'Autres' },
];

export const prestataire = [
  { name: 'Métier' },
  { name: 'Restaurant' },
  { name: 'Hôtel' },
  { name: 'Bar' },
  { name: 'Maquis' },
  { name: 'Agence de voyages' },
  { name: 'Location & vente de véhicule' },
  { name: 'Autres' },
];

export const conditions = [
  { name: 'Nouveau' },
  { name: 'Occasion' },
  { name: 'Reconditionné' },
];

export const deliveryTime = [
  { name: '1 - 2 jours' },
  { name: '2 - 4 jours' },
  { name: '4 - 6 jours' },
  { name: '6 - 8 jours' },
  { name: '8 - 10 jours' },
  { name: 'plus de 10 jours' },
];

export const livraisons = [
  { name: 'Livraison gratuite' },
  { name: 'Livraison facturée' },
];

export const shippingFees = [
  { name: '1000' },
  { name: '1500' },
  { name: '2000' },
  { name: '2500' },
  { name: '3000' },
  { name: '3500' },
  { name: '4000' },
  { name: '4500' },
  { name: '5000' },
  { name: '8000' },
  { name: '10000' },
  { name: '15000' },
  { name: '20000' },
];

export const expertiseData = [
  {
    title: 'Services',
    description: `
    Explorez une variété d'entreprises. Choisissez selon vos critères. Profils complets mettant en avant les activités pour des décisions éclairées.`,
    icon: <FontAwesomeIcon icon={faTools} />,
  },
  {
    title: 'Commerce',
    description: `Commercez sereinement, bénéficiez de transparence et de partenaires vérifiés pour une expérience client authentique et satisfaisante.`,
    icon: <FontAwesomeIcon icon={faShoppingCart} />,
  },
  {
    title: 'Gestion',
    description: `Libérez votre temps d'entrepreneur. Nous gérons les tâches administratives pour que vous puissiez vous concentrer sur l'essentiel.`,
    icon: <FontAwesomeIcon icon={faChartBar} />,
  },
  {
    title: 'Jobs en ligne',
    description: `Nous répondons à vos besoins en saisie de données, publicités en temps réel, et bien plus. Profitez d'une expertise complète en ligne.`,
    icon: <FontAwesomeIcon icon={faLaptop} />,
  },
  {
    title: 'Projet',
    description: `Nous vous accompagnons de A à Z pour vos projets clés en main ou de financement. Contactez-nous dès aujourd'hui pour en discuter.`,
    icon: <FontAwesomeIcon icon={faProjectDiagram} />,
  },
  {
    title: 'Partenariat',
    description: `Notre plateforme, alliant réseau d’affaires et Marketplace, propose une expérience complète et transparente adaptée aux besoins locaux et mondiaux.`,
    icon: <FontAwesomeIcon icon={faHandshake} />,
  },
];
