import React, { useEffect, useState } from 'react';
import Layout from '../components/admin/Layout';
import FormInput from '../utilities/utils-components/FormInput';
import FormTextarea from '../utilities/utils-components/FormTextarea';
import SelectInput from '../utilities/utils-components/SelectInput';
import { commercial, countries, prestataire, domaineTypes } from '../data/data';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import { useDocument } from '../hooks/useDocument';
import { useMyContext } from '../context/UseContext';
import { useFireStore } from '../hooks/useFireStore';
import Spinner from '../utilities/utils-components/Spinner';
import { useStorage } from '../hooks/useStorage';
import { toast } from 'react-toastify';

const ProfilPage = () => {
  const { updateDocument, response } = useFireStore('users');

  const {
    images,
    progress,
    uploadFiles,
    uploadErr,
    deleteFile,
    setUploadErr,
    successfully,
    success: imageSuccess,
  } = useStorage();

  const {
    document: doc,
    isPending: docIspending,
    error: docError,
    success: docSuccess,
  } = response;

  const { user, profil } = useMyContext();
  const { docResponse } = useDocument('users', user.uid);
  const { document, isPending, error, success } = docResponse;

  const [selectedData, setSelectedData] = useState(commercial);
  const [contrySelected, setContrySelected] = useState(countries[0]);
  const [companyTypes, setCompanyTypes] = useState(selectedData[0]);

  const [domaine, setDomaie] = useState(domaineTypes[0]);

  useEffect(() => {
    switch (domaine.name) {
      case 'Prestation de service':
        setSelectedData(prestataire);
        setCompanyTypes(prestataire[0]);
        break;
      default:
        setSelectedData(commercial);
        setCompanyTypes(commercial[0]);
        break;
    }
  }, [domaine.name]);

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState();
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [bio, setBio] = useState();
  const [banner, setBanner] = useState([]);
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [isSellerRequested, setIsSellerRequested] = useState(false);
  const [contrieCode, setcontrieCode] = useState('');

  const handleUpload = (event) => {
    uploadFiles(event.target.files, user);
  };

  useEffect(() => {
    if (imageSuccess) {
      setBanner((prev) => [...prev, ...images]);
    }
  }, [imageSuccess]);

  const deleteFileHadler = async (imageUrl, index) => {
    await deleteFile(imageUrl);
    setBanner((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (document) {
      setUserName(document.userName ?? '');
      setEmail(document.email);
      setAddress(document.address ?? '');
      setPhone(document.phone ?? '');
      setBio(document.bio ?? '');
      setBanner(document.banner ?? '');
      setFacebook(document.facebook ?? '');
      setInstagram(document.instagram ?? '');
      setLinkedin(document.linkedin ?? '');
      setIsSellerRequested(document.isSellerRequested);
      {
        document?.domaine && setDomaie({ name: document.domaine });
      }

      {
        document?.countries && setContrySelected({ name: document.countries });
      }

      {
        document?.companyType &&
          setCompanyTypes({ name: document.companyType });
      }
      {
        document?.contrieCode && setcontrieCode(document.contrieCode);
      }
    }
  }, [document]);

  const submitForm = async (e) => {
    e.preventDefault();

    const updatedFields = {
      ...document,
      userName: userName.toLowerCase(),
      email,
      address,
      phone,
      bio,
      banner,
      facebook,
      instagram,
      linkedin,
      isSellerRequested,
      domaine: domaine.name,
      countries: contrySelected.name,
      companyType: companyTypes.name,
      contrieCode:
        contrieCode && !contrySelected.code
          ? contrieCode
          : contrySelected.code
          ? contrySelected.code
          : '',
      createdAt: Date.now(),
    };

    console.log('============', updatedFields);
    await updateDocument(user.uid, updatedFields);
  };

  useEffect(() => {
    if (response.success) {
      toast.success('Profil mise à jour avec success!', {
        position: 'top-center',
      });
    }
  }, [response.success]);

  useEffect(() => {
    if (response.error) {
      toast.error('Erreur de mise a jour!', {
        position: 'top-center',
      });
    }
  }, [response.error]);

  return (
    <Layout>
      <div className=' bg-white shadow-sm rounded-md p-4'>
        <Spinner loading={docIspending} />
        <h2 className=' mb-3'>Profil entreprise</h2>
        <form onSubmit={submitForm}>
          <div className=' grid gap-4 grid-cols-1 md:grid-cols-2 mb-2'>
            <FormInput
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
              title={`Entreprise`}
              type='text'
              placeholder={`Entrer un nom d'entreprise`}
            />
            <FormInput
              disable={true}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              title={`Email`}
              type='email'
              placeholder={`Entrer votre email`}
            />
          </div>
          <div className=' grid gap-4 grid-cols-1 md:grid-cols-2'>
            <FormInput
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              title={`Adresse`}
              type='text'
              placeholder={`Entrer votre Localité`}
            />
            <FormInput
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              title={`Téléphone`}
              type='text'
              placeholder={`Entrer votre numéro de téléphone`}
            />
          </div>
          <div className=' my-4'>
            <span
              className={`${
                isSellerRequested ? ' bg-red-100' : 'bg-blue-100'
              }  px-4 py-2 cursor-pointer`}
              onClick={() => setIsSellerRequested(!isSellerRequested)}
            >
              {isSellerRequested
                ? 'Annuler  la demande'
                : 'Devenir prestataire'}
            </span>
          </div>
          {isSellerRequested && (
            <>
              <FormTextarea
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                title={`Bio`}
                placeholder={`Donner une description de votre entreprise`}
              />
              <div className=' w-full mb-2 relative z-40'>
                <label className=' text-base md:text-[1.2rem] font-medium'>
                  Domaine
                </label>
                <SelectInput
                  inputEls={domaineTypes}
                  setSelected={setDomaie}
                  selected={domaine}
                  bg='#e8e8e8'
                  iconColor='text-black'
                />
              </div>
              <div className=' grid gap-4 grid-cols-1 mdgrid-cols-2 mb-2  '>
                <div className=' w-full z-10'>
                  <label className=' text-base md:text-[1.2rem] font-medium'>
                    Type d'entreprise
                  </label>
                  <SelectInput
                    inputEls={selectedData}
                    setSelected={setCompanyTypes}
                    selected={companyTypes}
                    bg='#e8e8e8'
                    iconColor='text-black'
                  />
                </div>
                <div className=' w-full'>
                  <label className=' text-base md:text-[1.2rem] font-medium'>
                    Pays
                  </label>
                  <SelectInput
                    inputEls={countries}
                    setSelected={setContrySelected}
                    selected={contrySelected}
                    bg='#e8e8e8'
                    iconColor='text-black'
                  />
                </div>
              </div>
              <div className=' grid gap-4 grid-cols-1 md:grid-cols-3 my-3'>
                <FormInput
                  value={facebook}
                  onChange={(event) => setFacebook(event.target.value)}
                  title={`Facebook`}
                  type='url'
                  placeholder={`Entrer le lien`}
                />
                <FormInput
                  value={instagram}
                  onChange={(event) => setInstagram(event.target.value)}
                  title={`Instagram
              `}
                  type='url'
                  placeholder={`Entrer le lien`}
                />
                <FormInput
                  value={linkedin}
                  onChange={(event) => setLinkedin(event.target.value)}
                  title={`LinkedIn`}
                  type='url'
                  placeholder={`Entrer le lien`}
                />
              </div>
              <label htmlFor='banner'>
                <input
                  style={{ display: 'none' }}
                  multiple
                  type='file'
                  id='banner'
                  onChange={handleUpload}
                />
                <div
                  title='Banniere entreprise'
                  className='border-2 flex rounded-md border-dashed cursor-pointer border-[#114cb3] justify-center p-2 w-[60%] lg:w-[10%]'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-10 h-10  text-[#114cb3]   '
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z'
                    />
                  </svg>
                </div>
              </label>
              {progress > 0 && (
                <div style={{ backgroundColor: '#dbeafe', width: '100%' }}>
                  <span
                    style={{
                      display: 'flex',
                      width: `${progress}%`,
                      backgroundColor: '#114cb3',
                      margin: '5px 0px',
                      color: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {progress}%
                  </span>
                </div>
              )}
              {banner.length > 0 && (
                <div className=' flex space-x-2 my-4'>
                  {banner.map((imageUrl, index) => (
                    <div className=' relative z-0' key={index}>
                      <img
                        src={imageUrl}
                        alt='image'
                        className=' h-16 w-20 object-cover'
                      />
                      <svg
                        onClick={() => deleteFileHadler(imageUrl, index)}
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6 absolute top-0 right-0 cursor-pointer text-red-500'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 18 18 6M6 6l12 12'
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          <div className=' mt-3'>
            <PrimaryButton text={'Mettre à jour'} />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ProfilPage;
