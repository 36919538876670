import React, { useEffect, useState } from 'react';
import Loader from '../utilities/utils-components/Loader';
import GridContainer from '../components/Company/GridContainer';
import Pagination from '../utilities/utils-components/Pagination';
import { CompanyHeader } from '../components/Company/CompanyHeader';
import { useCollection } from '../hooks/useCollection';
import ErreorMessage from '../utilities/utils-components/ErreorMessage';
import NotFound from '../utilities/utils-components/NotFound';
import PagesHeader from '../utilities/PagesHeader';

const SearchPage = () => {
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const [company, setCompany] = useState('');
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const location = searchParams.get('location');
    const company = searchParams.get('company');
    const title = searchParams.get('title');
    setTitle(!title ? '' : title);
    setLocation(!location ? '' : location);
    setCompany(!company ? '' : company);
  }, [searchParams]);

  const { documents, loading, error } = useCollection(
    'users',
    location,
    company,
    title
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <main>
        <PagesHeader
          images={
            'https://images.unsplash.com/photo-1558402955-dabff43cb9c0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          }
          pageTitle={'RESULTAS DE Recherche'}
        />

        {loading ? (
          <div className=' h-40'>
            <Loader />
          </div>
        ) : error ? (
          <ErreorMessage message={'Erreur de recuperation des service'} />
        ) : currentProducts.length === 0 ? (
          <div className=' h-[100px] pt-4 w-[60%] mx-auto justify-center items-center  flex  text-center'>
            <NotFound message='Aucun service trouvé' />
          </div>
        ) : (
          <>
            <GridContainer companies={currentProducts} width={'60%'} />
            <Pagination
              documents={documents}
              productsPerPage={productsPerPage}
              currentPage={currentPage}
              paginate={paginate}
            />
          </>
        )}
      </main>
    </>
  );
};

export default SearchPage;
