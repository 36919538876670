import { useEffect } from 'react';
import { startOfMonth, endOfMonth, getUnixTime } from 'date-fns';
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { useReducer } from 'react';

const startOfCurrentMonth = startOfMonth(new Date());
const endOfCurrentMonth = endOfMonth(new Date());
const startTimestamp = startOfCurrentMonth.getTime();
const endTimestamp = endOfCurrentMonth.getTime();
const endTimestamps = endOfMonth(new Date(1712136560643));
const endTimestampsss = endOfMonth(new Date(1714521599));

const actionTypes = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.PENDING:
      return { ...state, isPending: true, success: false, error: null };
    case actionTypes.SUCCESS:
      return {
        ...state,
        isPending: false,
        success: true,
        error: null,
        orderDoc: action.orderDoc,
        document: action.payload,
      };
    case actionTypes.ERROR:
      return {
        ...state,
        isPending: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const initialState = {
  orderDoc: null,
  document: null,
  isPending: false,
  success: false,
  error: null,
};

export const useMonthIncom = (collectionDoc, userId) => {
  const [docResponse, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let ref = collection(db, collectionDoc);
    let docRef = collection(db, collectionDoc);

    dispatch({ type: 'PENDING' });

    ref = query(
      ref,
      where('uid', '==', userId),
      where('isDelivered', '==', true),
      where('createdAt', '>=', startTimestamp),
      where('createdAt', '<=', endTimestamp)
    );

    docRef = query(
      docRef,
      where('uid', '==', userId),
      where('isDelivered', '==', true)
    );

    const unsub = onSnapshot(
      ref,
      (snapshop) => {
        let results = [];

        snapshop.docs.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });

        onSnapshot(docRef, (snapshop) => {
          let document = [];

          snapshop.docs.forEach((doc) => {
            document.push({ id: doc.id, ...doc.data() });
          });

          console.log(document);

          dispatch({ type: 'SUCCESS', payload: results, orderDoc: document });
        });
      },
      (err) => {
        console.log('collection', err);
        dispatch({
          type: 'ERROR',
          payload: 'Impossible de recurer les données',
        });
      }
    );

    return () => unsub();
  }, [collectionDoc, userId]);

  return { docResponse };
};
