import React, { useEffect, useState } from 'react';
import Layout from '../components/admin/Layout';
import Spinner from '../utilities/utils-components/Spinner';
import FormInput from '../utilities/utils-components/FormInput';
import { useStorage } from '../hooks/useStorage';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../context/UseContext';
import { useFireStore } from '../hooks/useFireStore';
import { toast } from 'react-toastify';
import FormTextarea from '../utilities/utils-components/FormTextarea';
import { useCollection } from '../hooks/useCollection';
import SelectInput from '../utilities/utils-components/SelectInput';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import SwitchInput from '../utilities/utils-components/SwitchInput';

const AddAds = () => {
  const { user, profil } = useMyContext();
  const navigate = useNavigate();
  const {
    images,
    progress,
    uploadFiles,
    uploadErr,
    deleteFile,
    setUploadErr,
    successfully,
    success: imageSuccess,
  } = useStorage();

  const { addDocument, response } = useFireStore('ads');
  const { documents, loading } = useCollection('users');

  let users = [{ name: 'Entreprise' }];

  for (const document of documents) {
    users.push({ name: document.userName, id: document.id });
  }

  const { document, isPending, error, success } = response;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState();
  const [seller, setSeller] = useState(users[0]);
  const [isVedette, setIsVedette] = useState(false);

  const handleUpload = (event) => {
    uploadFiles(event.target.files, user);
  };

  useEffect(() => {
    if (imageSuccess) {
      setImage(images);
    }
  }, [imageSuccess]);

  const deleteFileHadler = async (imageUrl) => {
    await deleteFile(imageUrl);
    setImage('');
  };

  async function submitForm(e) {
    e.preventDefault();
    if (seller.name === 'Entreprise') {
      toast.error('Veillez selectionner une entreprise', {
        position: 'top-center',
      });
    } else {
      const updatedFields = {
        title,
        description,
        image,
        seller,
        isVedette,
        createdAt: Date.now(),
      };

      await addDocument(updatedFields);
      navigate('/admin/annonces');
    }
  }

  useEffect(() => {
    if (error) {
      toast.error("Une erreur s'est produite", {
        position: 'top-center',
      });
    }
  }, [error]);

  return (
    <Layout>
      <div className=' bg-white shadow-sm rounded-md p-4'>
        <Spinner loading={isPending} />
        <h2 className=' mb-3'>Nouvelle annonces</h2>
        <form onSubmit={submitForm}>
          <FormInput
            required={true}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            title={`Titre`}
            type='text'
            placeholder={`Saisisser un titre`}
          />
          <FormTextarea
            required={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            title={'Description'}
            placeholder={`Donner une description du service`}
          />
          <div className=' w-full mb-2 relative z-[75]'>
            <label className=' text-[1.2rem] font-medium'>Entreprise</label>
            <SelectInput
              inputEls={users}
              setSelected={setSeller}
              selected={seller}
              bg='#e8e8e8'
              iconColor='text-black'
            />
          </div>
          <SwitchInput setIsVedette={setIsVedette} isVedette={isVedette} />
          <label htmlFor='image'>
            <span className=' text-[1.2rem] font-medium'>Images</span>
            <input
              style={{ display: 'none' }}
              type='file'
              id='image'
              onChange={handleUpload}
            />
            <div
              title='Banniere entreprise'
              className='border-2 flex rounded-md border-dashed cursor-pointer border-[#114cb3] justify-center p-2 w-[10%]'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-10 h-10  text-[#114cb3]   '
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z'
                />
              </svg>
            </div>
          </label>
          {progress > 0 && (
            <div style={{ backgroundColor: '#dbeafe', width: '100%' }}>
              <span
                style={{
                  display: 'flex',
                  width: `${progress}%`,
                  backgroundColor: '#114cb3',
                  margin: '5px 0px',
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {progress}%
              </span>
            </div>
          )}

          {image && (
            <div className=' flex space-x-2 my-4'>
              <div className=' relative z-0'>
                <img
                  src={image}
                  alt='image'
                  className=' h-16 w-20 object-cover'
                />
                <svg
                  onClick={() => deleteFileHadler(image)}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6 absolute top-0 right-0 cursor-pointer text-red-500'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18 18 6M6 6l12 12'
                  />
                </svg>
              </div>
            </div>
          )}

          <div className=' mt-3'>
            <PrimaryButton text={'Enregistrer'} />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddAds;
