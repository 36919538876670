import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMyContext } from '../context/UseContext';
import logo from '../public/images/logo.png';

const Header = () => {
  const { user, profil } = useMyContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenu, setIsMenu] = useState(false);

  return (
    <header>
      <div
        onClick={() => setIsMenu(false)}
        className={`${
          isMenu ? 'show-menu' : 'hide-menu'
        } lg:hidden w-[100%] fixed top-0 bottom-0  bg-[#00000087]  z-[200]`}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className='bg-[#f5f5f5] shadow-md h-full z-[9999] w-[80%]'
        >
          <div className=' shadow-md bg-[#114cb3] text-white  py-4 px-2 flex items-center justify-between w-full'>
            <span className=' text-[0.8rem]'>
              Bienvenu a <br />
              <span className=' text-[1rem] font-semibold uppercase'>
                pretfog
              </span>
            </span>
            <Link
              to={`${user ? '/admin' : '/connexion'} `}
              onClick={() => setIsMenu(false)}
              className=' flex items-center text-[0.7rem]'
            >
              {!user && <span> Identifiez-vous</span>}

              <div className=' bg-[#3c6fc6] rounded-full w-[35px] h-[35px] flex items-center justify-center '>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-5 h-5'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z'
                  />
                </svg>
              </div>
            </Link>
          </div>
          <div className=' '>
            <ul className=' p-3 flex flex-col space-y-1'>
              <li
                onClick={() => setIsMenu(false)}
                className=' border-b-[1px] border-b-gray-200 pb-2'
              >
                <Link to={'/entreprise'}>Espace entreprise</Link>
              </li>

              <li
                onClick={() => setIsMenu(false)}
                className=' border-b-[1px] border-b-gray-200 pb-2'
              >
                <Link to={'/annonces'}>Page d'annonce</Link>
              </li>
              <li
                onClick={() => setIsMenu(false)}
                className=' border-b-[1px] border-b-gray-200 pb-2'
              >
                <Link to={'/faqs'}>Faqs</Link>
              </li>
            </ul>
          </div>
        </div>
        <button
          onClick={() => setIsMenu(false)}
          className=' absolute right-[1rem] top-[5%]'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-10 h-10 text-white'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>
      </div>
      <div className='bg-[#e8e8e8e5] w-full z-[150] h-[70px] fixed'>
        <nav className=' h-[70px]    right-[2.5%] left-[2.5%] xl:left-[5%] xl:right-[5%] 2xl:right-[10%] 2xl:left-[10%]  w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto flex justify-between items-center '>
          <div>
            <Link to={'/'}>
              <img src={logo} className=' h-[90px]' alt='logo' />
            </Link>
          </div>

          <ul className=' hidden lg:flex  text-[1.2rem] space-x-6'>
            <li
              className={`${
                location.pathname === '/entreprise' && ' opacity-60'
              }`}
            >
              <Link to={'/entreprise'}>Espace entreprise</Link>
            </li>
            <li
              className={`${
                location.pathname === '/annonces' && ' opacity-60'
              }`}
            >
              <Link to={'/annonces'}>Page d'annonce</Link>
            </li>
            <li className={`${location.pathname === '/faqs' && ' opacity-60'}`}>
              <Link to={'/faqs'}>Faqs</Link>
            </li>
          </ul>
          <button onClick={() => setIsMenu(true)} className=' block lg:hidden'>
            <svg
              fill='#114cb3'
              className=' h-6'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 448 512'
            >
              <path d='M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z' />
            </svg>
          </button>
          {user ? (
            <>
              <button
                onClick={() => navigate('/admin')}
                className=' hidden lg:flex border-[2px] border-[#d60e0e] px-5 py-[5px] rounded-md   items-center space-x-1'
              >
                <span className=' lg:hidden'>
                  <svg
                    className=' h-4'
                    fill='#114cb3'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z' />
                  </svg>
                </span>

                <span className=' hidden lg:block'>
                  <svg
                    className=' h-4'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 384 512'
                    fill='#114cb3'
                  >
                    <path d='M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z' />
                  </svg>
                </span>
                <span className=' hidden lg:block'> Tableau de bord</span>
              </button>
            </>
          ) : (
            <div className=' hidden lg:flex space-x-4'>
              <button
                onClick={() => navigate('/inscription')}
                className=' bg-[#d60e0e] px-5 py-[5px] text-white'
              >
                Inscription
              </button>
              <button
                onClick={() => navigate('/connexion')}
                className='border-[2px] border-[#d60e0e] px-5 py-[5px] '
              >
                Connexion
              </button>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
