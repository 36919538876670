import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../../context/UseContext';

const CallToAction = () => {
  const { user, profil } = useMyContext();
  const navigate = useNavigate();
  return (
    <div className=' text-white bg-[#114cb3] flex flex-col justify-center items-center py-12'>
      <div className=' text-center my-6'>
        <h2 className=' text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] py-2'>
          Prêt à commencer dès maintenant ?
        </h2>
        <p className=' text-[0.9rem] lg:text-[1rem]'>
          Explorez notre répertoire d'entreprises pour trouver des services, des
          produits et des informations sur diverses entreprises.
        </p>
      </div>
      <div className=' flex space-x-4'>
        <button
          onClick={() => navigate(`${user ? '/admin' : '/inscription'} `)}
          className=' bg-[#00000079] border-2 hover:border-[#00000079] hover:bg-transparent transition-all px-3  lg:px-8 py-2 text-white rounded-md'
        >
          {user ? 'Demarrer' : 'Inscription'}
        </button>

        <button
          onClick={() => navigate(`${user ? '/entreprise' : '/inscription'} `)}
          className=' bg-[#d60e0e] border-2 hover:border-[#d60e0e] transition-all hover:bg-[#00000000]  px-3  lg:px-8 py-2 text-white rounded-md'
        >
          {user ? 'Decouvrir' : 'Inscription'}
        </button>
      </div>
    </div>
  );
};

export default CallToAction;
