import React, { useEffect, useState } from 'react';
import ProductSummary from '../components/shippingAddresse/ProductSummary';
import ShippingProduct from '../components/shippingAddresse/ShippingProduct';
import ShippingDetails from '../components/shippingAddresse/ShippingDetails';
import { useFireStore } from '../hooks/useFireStore';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../context/UseContext';

const ShippingAddress = () => {
  const { user, profil } = useMyContext();
  const { addDocument, response } = useFireStore('orders');
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [qty, setQty] = useState(1);
  const [totalAmout, setTotalAmout] = useState();
  const [brutTotalAmout, setBrutTotalAmout] = useState();
  const [isQty, setIsQty] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [bookingDays, setBookingDays] = useState([]);
  console.log(bookingDays);

  let shippingAddresse;
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('product'));
    if (!data) {
      return navigate('/');
    }

    setData(data);
    setQty(data.product.qty);
    setTotalAmout(data.product.totalAmout);
    setBrutTotalAmout(data.product.brutTotalAmout);
    setBookingDays(data?.product?.bookingDays);
  }, []);

  useEffect(() => {
    if (isQty) {
      const productData = JSON.parse(localStorage.getItem('product'));
      const totalAmout =
        Number(productData.product.price) * qty +
        Number(productData.product.shippingFee);
      const brutTotalAmout = Number(productData.product.normalePrice) * qty;

      const reduction =
        Number(productData.product.normalePrice) >
        Number(productData.product.price)
          ? (Number(productData.product.normalePrice) -
              Number(productData.product.price)) *
            qty
          : 0;

      localStorage.setItem(
        'product',
        JSON.stringify({
          product: {
            ...productData.product,
            qty,
            totalAmout,
            brutTotalAmout,
            reduction,
          },
          seller: { ...productData.seller },
        })
      );
      const data = JSON.parse(localStorage.getItem('product'));

      setData(data);
      setTotalAmout(data.product.totalAmout);
      setBrutTotalAmout(data.product.brutTotalAmout);
      setIsQty(false);
    }
  }, [qty, isQty]);

  function shippingAdressHandler(data) {
    shippingAddresse = data;
  }

  async function submitHandler() {
    const order = {
      productImage: data.product.gallery[0],
      title: data.product.title,
      amount: data.product.price,
      qty: data.product.qty,
      totalAmout: data.product.totalAmout,
      normalePrice: data.product.normalePrice,
      reduction: data.product.reduction,
      poductId: data.product.id,
      shippingFee: data.product.shippingFee,
      brutTotalAmout: data.product.brutTotalAmout,
      sellerId: data.product.uid,
      deleveryTime: data.product.deleveryTime,
      sellerId: data.seller.id,
      sellerName: data.seller.name,
      livraison: data.product.livraison,
      category: data.product.companyTypes,
      uid: user.uid,
      createdAt: Date.now(),
      shippingAddresse,
      isDelivered: false,
      bookingDays: bookingDays ? bookingDays : '',
      email: profil.email,
    };
    console.log(order);
    await addDocument(order);
  }

  useEffect(() => {
    if (response.success) {
      localStorage.removeItem('product');
      navigate('/commande/termine');
    }
  }, [response.success]);

  return (
    <div className=' flex flex-col md:grid   py-8 gap-10 w-[85%] md:w-[95%] lg:w-[80%] mx-auto'>
      <div className=' grid grid-cols-4 gap-8 '>
        <div className=' col-span-4  md:col-span-2 lg:col-span-3  '>
          <h2 className=' font-semibold mb-3 text-[1.2rem]'>Product</h2>
          <ShippingProduct
            setIsQty={setIsQty}
            qty={qty}
            setQty={setQty}
            data={data}
          />
          <ShippingDetails
            setIsOk={setIsOk}
            onShippingAdresse={shippingAdressHandler}
          />
        </div>
        <div className=' col-span-4 md:col-span-2 lg:col-span-1'>
          <ProductSummary
            isOk={isOk}
            submitHandler={submitHandler}
            brutTotalAmout={brutTotalAmout}
            data={data}
            totalAmout={totalAmout}
            response={response}
          />
        </div>
      </div>
    </div>
  );
};

export default ShippingAddress;
