import React from 'react';
import { Link } from 'react-router-dom';
import Comment from './Comment';
import Description from './Description';
import UserProfil from './UserProfil';
import Datepicker from './Datepicker';

const DescriptionSection = ({
  orders,
  setBookingDays,
  service,
  id,
  seller,
  isIncludes,
  setIsIncludes,
}) => {
  return (
    <div className=' w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto   grid grid-cols-4 gap-4 mt-8'>
      <div className=' col-span-4 md:col-span-3'>
        <Description service={service} />
        {(service.companyTypes === 'Hôtel' ||
          service.companyTypes === 'Location de véhicule') && (
          <Datepicker
            isIncludes={isIncludes}
            setIsIncludes={setIsIncludes}
            orders={orders}
            setBookingDays={setBookingDays}
          />
        )}

        <Comment service={service} id={id} />
      </div>
      <div className='col-span-4 md:col-span-1'>
        <UserProfil seller={seller} />
      </div>
    </div>
  );
};

export default DescriptionSection;
