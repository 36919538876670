import React from 'react';

const OurExpertise = ({ icon, title, description, index }) => {
  return (
    <div
      className={`expertise-card py-3 px-1 relative rounded-lg  my-col-${index}`}
    >
      <div className=' text-white flex flex-col items-center justify-center h-full text-center'>
        {' '}
        <div className=' text-[2.5rem]'>{icon}</div>
        <h3 className=' text-[1.2rem]'>{title}</h3>
        <p className='expertise-description'>{description}</p>
      </div>
    </div>
  );
};

export default OurExpertise;
