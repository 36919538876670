import React, { useEffect, useState } from 'react';
import Layout from '../components/admin/Layout';
import FormInput from '../utilities/utils-components/FormInput';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import FormTextarea from '../utilities/utils-components/FormTextarea';
import SelectInput from '../utilities/utils-components/SelectInput';
import {
  commercial,
  countries,
  prestataire,
  domaineTypes,
  deliveryTime,
  conditions,
  shippingFees,
  livraisons,
} from '../data/data';
import Spinner from '../utilities/utils-components/Spinner';
import { useFireStore } from '../hooks/useFireStore';
import { useStorage } from '../hooks/useStorage';
import { useMyContext } from '../context/UseContext';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument';

const ServiceEdict = () => {
  const { user, profil } = useMyContext();
  const params = useParams();
  const { id } = params;
  const { docResponse } = useDocument('products', id);
  const {
    document: doc,
    isPending: docLoading,
    error: docError,
    success: docSuccess,
  } = docResponse;

  const navigate = useNavigate();

  const {
    images,
    progress,
    uploadFiles,
    uploadErr,
    deleteFile,
    setUploadErr,
    successfully,
    success: imageSuccess,
  } = useStorage();

  const { updateDocument, response } = useFireStore('products');
  const { document, isPending, error, success } = response;

  const [selectedData, setSelectedData] = useState(commercial);
  const [contrySelected, setContrySelected] = useState(countries[0]);
  const [companyTypes, setCompanyTypes] = useState(selectedData[0]);
  const [domaine, setDomaie] = useState(domaineTypes[0]);
  const [condition, setCondition] = useState(conditions[0]);
  const [deleveryTime, setDeleveryTime] = useState(deliveryTime[0]);
  const [livraison, setLivraison] = useState(livraisons[0]);
  const [shippingFee, setShippingFee] = useState(shippingFees[0]);

  const [price, setPrice] = useState('');
  const [normalePrice, setNormalePrice] = useState('');
  const [title, setTitle] = useState('');
  const [shortdescription, setShortdescription] = useState('');
  const [description, setDescription] = useState('');
  const [gallery, setGallery] = useState([]);
  const [marque, setMarque] = useState('');

  const [loding, seLoding] = useState(false);

  useEffect(() => {
    const redirectIfNeeded = () => {
      seLoding(true);
      if (profil && !profil.isSeller && !profil.isAdmin) {
        navigate('/profil/edit');
      }
      seLoding(true);
    };

    redirectIfNeeded();
  }, [profil && profil.isSeller, profil && profil.isAdmin]);

  useEffect(() => {
    if (doc) {
      setPrice(doc.price);
      setTitle(doc.title);
      setShortdescription(doc.shortdescription);
      setDescription(doc.description);
      setGallery(doc.gallery);
      setDomaie({ name: doc.domaine });
      setContrySelected({ name: doc.contrySelected });
      setCompanyTypes({ name: doc.companyTypes });
      setMarque(doc.marque);
      setCondition({ name: doc.condition });
      setDeleveryTime({ name: doc.deleveryTime });
      setNormalePrice(doc.normalePrice);
      setLivraison({ name: doc.livraison });
      setShippingFee({ name: doc.shippingFee });
    }
  }, [doc]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setPrice(inputValue);
    }
  };

  const normalePriceChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setNormalePrice(inputValue);
    }
  };

  useEffect(() => {
    switch (domaine.name) {
      case 'Commerce':
        setSelectedData(commercial);
        setCompanyTypes(commercial[0]);
        break;
      default:
        setSelectedData(prestataire);
        setCompanyTypes(prestataire[0]);
        break;
    }
  }, [domaine.name]);

  const handleUpload = (event) => {
    uploadFiles(event.target.files, user);
  };

  useEffect(() => {
    if (imageSuccess) {
      setGallery((prev) => [...prev, ...images]);
    }
  }, [imageSuccess]);

  const deleteFileHadler = async (imageUrl, index) => {
    await deleteFile(imageUrl);
    setGallery((prev) => prev.filter((_, i) => i !== index));
  };

  async function submitForm(e) {
    e.preventDefault();

    const updatedFields = {
      ...doc,
      price,
      title,
      shortdescription,
      description,
      gallery,
      domaine: domaine.name,
      companyTypes: companyTypes.name,
      contrySelected: contrySelected.name,
      condition: condition.name,
      deleveryTime: deleveryTime.name,
      normalePrice,
      marque,
      shippingFee:
        livraison.name === 'Livraison gratuite' ? '' : shippingFee.name,
      livraison: livraison.name,
      uid: user.uid,
    };

    await updateDocument(id, updatedFields);
    navigate('/services');
  }

  useEffect(() => {
    if (error) {
      toast.error("Une erreur s'est produite", {
        position: 'top-center',
      });
    }
  }, [error]);
  return (
    <Layout id={id}>
      {loding && (
        <div className=' bg-white shadow-sm rounded-md p-4'>
          <Spinner loading={isPending} />
          <h2 className=' mb-3'>Nouveau service</h2>
          <form onSubmit={submitForm}>
            <div className=' grid gap-4 grid-cols-1 mb-2'>
              <FormInput
                required={true}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                title={`Titre`}
                type='text'
                placeholder={`Saisisser un titre`}
              />
            </div>
            <div className=' my-2 grid gap-4 '>
              <FormTextarea
                required={true}
                value={shortdescription}
                onChange={(e) => setShortdescription(e.target.value)}
                title={` Description courte `}
                type='text'
                placeholder={`Ajouter une courte description`}
              />
            </div>
            <FormTextarea
              required={true}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              title={'Description'}
              placeholder={`Donner une description du service`}
            />
            <div className=' w-full mb-2 relative z-[80]'>
              <label className=' text-[1.2rem] font-medium'>Domaine</label>
              <SelectInput
                inputEls={domaineTypes}
                setSelected={setDomaie}
                selected={domaine}
                bg='#e8e8e8'
                iconColor='text-black'
              />
            </div>
            <div className=' grid gap-4 grid-cols-2 mb-2  '>
              <div className=' w-full z-[70]'>
                <label className=' text-[1.2rem] font-medium'>
                  Type d'entreprise
                </label>
                <SelectInput
                  inputEls={selectedData}
                  setSelected={setCompanyTypes}
                  selected={companyTypes}
                  bg='#e8e8e8'
                  iconColor='text-black'
                />
              </div>
              <div className=' w-full z-[70]'>
                <label className=' text-[1.2rem] font-medium'>Pays</label>
                <SelectInput
                  inputEls={countries}
                  setSelected={setContrySelected}
                  selected={contrySelected}
                  bg='#e8e8e8'
                  iconColor='text-black'
                />
              </div>
            </div>

            {domaine.name === 'Commerce' && (
              <>
                <div className=' grid gap-4 grid-cols-2 mb-2  '>
                  <div className=' relative w-full z-[60]'>
                    <label className=' text-[1.2rem] font-medium'>
                      Condition
                    </label>
                    <SelectInput
                      inputEls={conditions}
                      setSelected={setCondition}
                      selected={condition}
                      bg='#e8e8e8'
                      iconColor='text-black'
                    />
                  </div>
                  <div className=' w-full'>
                    <FormInput
                      value={marque}
                      onChange={(e) => setMarque(e.target.value)}
                      title={`Marque`}
                      type='text'
                      placeholder={`Entrer la marque`}
                    />
                  </div>
                </div>

                <div className=' w-full relative  z-40'>
                  <label className=' text-[1.2rem] font-medium'>
                    Livraison
                  </label>
                  <SelectInput
                    inputEls={livraisons}
                    setSelected={setLivraison}
                    selected={livraison}
                    bg='#e8e8e8'
                    iconColor='text-black'
                  />
                </div>

                {livraison.name === 'Livraison facturée' && (
                  <div className=' w-full relative z-30 my-3'>
                    <label className=' text-[1.2rem] font-medium'>
                      Frais de livraison
                    </label>
                    <SelectInput
                      inputEls={shippingFees}
                      setSelected={setShippingFee}
                      selected={shippingFee}
                      bg='#e8e8e8'
                      iconColor='text-black'
                    />
                  </div>
                )}

                <div className=' w-full z-10 mb-2'>
                  <label className=' text-[1.2rem] font-medium'>
                    Temps de livraison
                  </label>
                  <SelectInput
                    inputEls={deliveryTime}
                    setSelected={setDeleveryTime}
                    selected={deleveryTime}
                    bg='#e8e8e8'
                    iconColor='text-black'
                  />
                </div>
                <div className='grid gap-4 grid-cols-2 mb-2'>
                  <div className=' w-full'>
                    <FormInput
                      value={normalePrice}
                      onChange={normalePriceChange}
                      title={`Prix régulier`}
                      type='text'
                      placeholder={`150000`}
                    />
                  </div>
                  <div className=' w-full'>
                    <FormInput
                      value={price}
                      onChange={handleChange}
                      title={`Prix réduit`}
                      type='text'
                      placeholder={`100000`}
                    />
                  </div>
                </div>
              </>
            )}

            {domaine.name === 'Prestation de service' && (
              <div className=' w-full'>
                <FormInput
                  value={price}
                  onChange={handleChange}
                  title={`${
                    companyTypes.name === 'Hôtel'
                      ? 'Prix/Nuit'
                      : companyTypes.name === 'Location de véhicule'
                      ? 'Prix par jour'
                      : 'Prix réduit'
                  }   `}
                  type='text'
                  placeholder={`100000`}
                />
              </div>
            )}
            <label htmlFor='product-files'>
              <span className=' text-[1.2rem] font-medium'>Images</span>
              <input
                style={{ display: 'none' }}
                multiple
                type='file'
                id='product-files'
                onChange={handleUpload}
              />
              <div
                title='Banniere entreprise'
                className='border-2 flex rounded-md border-dashed cursor-pointer border-[#114cb3] justify-center p-2 w-[10%]'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-10 h-10  text-[#114cb3]   '
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z'
                  />
                </svg>
              </div>
            </label>
            {progress > 0 && (
              <div style={{ backgroundColor: '#dbeafe', width: '100%' }}>
                <span
                  style={{
                    display: 'flex',
                    width: `${progress}%`,
                    backgroundColor: '#114cb3',
                    margin: '5px 0px',
                    color: 'white',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {progress}%
                </span>
              </div>
            )}
            {gallery.length > 0 && (
              <div className=' flex space-x-2 my-4'>
                {gallery.map((imageUrl, index) => (
                  <div className=' relative z-0' key={index}>
                    <img
                      src={imageUrl}
                      alt='image'
                      className=' h-16 w-20 object-cover'
                    />
                    <svg
                      onClick={() => deleteFileHadler(imageUrl, index)}
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 absolute top-0 right-0 cursor-pointer text-red-500'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M6 18 18 6M6 6l12 12'
                      />
                    </svg>
                  </div>
                ))}
              </div>
            )}

            <div className=' mt-3'>
              <PrimaryButton text={'Enregistrer'} />
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ServiceEdict;
