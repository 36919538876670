import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MobileSearchBar = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState('');

  function submitHandler(event) {
    navigate(`/recherche?title=${title}`);
  }
  return (
    <div className='bg-white flex w-full p-1  justify-between shadow-md lg:hidden  rounded-[5px]'>
      <div className='flex items-center flex-1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
          />
        </svg>

        <input
          //   onChange={(e) => setTitle(e.target.value)}
          className='p-2 flex-1 w-full outline-none'
          type='text'
          placeholder={`Taper le nom d'une entreprise`}
        />
      </div>
      <div>
        <button
          onClick={submitHandler}
          className=' bg-[#d60e0e] text-white w-full h-full px-5 py-2'
        >
          Recherche
        </button>
      </div>
    </div>
  );
};

export default MobileSearchBar;
