import React, { useEffect, useState } from 'react';
import AuthCrad from '../components/auth/AuthCrad';
import InputField from '../components/auth/InputField';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import { useAuth } from '../hooks/useAuth';
import Spinner from '../utilities/utils-components/Spinner';
import { toast } from 'react-toastify';
import { isInvalidEmail } from '../utils/invalideEmail';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();
  const { authState, signin, signup, logout, dispatchIfNotCancelled } =
    useAuth();
  const { user, isloading, error, success } = authState;

  const [useName, setUseName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: 'top-center',
      });
    }
  }, [error]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (useName.trim() === '' || !useName.trim()) {
      dispatchIfNotCancelled({
        type: 'ERROR',
        payload: "Nom d'utilisateur invalide",
      });

      setTimeout(() => {
        dispatchIfNotCancelled({
          type: 'RESET',
        });
      }, 3000);
    } else if (isInvalidEmail(email)) {
      dispatchIfNotCancelled({
        type: 'ERROR',
        payload: 'Adresse e-mail invalide',
      });
      setTimeout(() => {
        dispatchIfNotCancelled({
          type: 'RESET',
        });
      }, 3000);
    } else if (password.trim() !== confirmPassword) {
      dispatchIfNotCancelled({
        type: 'ERROR',
        payload: 'Mot de passe non conforme',
      });
      setTimeout(() => {
        dispatchIfNotCancelled({
          type: 'RESET',
        });
      }, 3000);
    } else {
      await signup(email, password, useName);
    }
  };
  return (
    <AuthCrad title='Inscription'>
      <form onSubmit={submitHandler} className=' flex flex-col space-y-4 mt-4'>
        <Spinner loading={isloading} />
        <InputField
          value={useName}
          onChange={(e) => setUseName(e.target.value)}
          title={`Nom d'utilisateur`}
          type='text'
          placeholder={`Entrer un nom d'utilisateur`}
        />
        <InputField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          title='Email'
          type='email'
          placeholder='Entrer votre email'
        />

        <InputField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          title='Mot de passe'
          type='password'
          placeholder='Entrer votre mot de passe'
        />
        <InputField
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          title='Confirmation du Mot de passe'
          type='password'
          placeholder='Confirmer le mot de passe'
        />
        <PrimaryButton text={`S'inscrire`} />
        <div className=' flex space-x-2 justify-end text-[#114cb3]'>
          <span
            onClick={() => navigate('/connexion')}
            className=' cursor-pointer hover:text-[#d60e0e]'
          >
            Vous avez dejà un compte? <span className>Connexion</span>
          </span>
        </div>
      </form>
    </AuthCrad>
  );
};

export default Register;
