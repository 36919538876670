import React from 'react';
import { priceFormatter } from '../../utils/priceFomatter';
import ClipLoader from 'react-spinners/ClipLoader';

const ProductSummary = ({
  submitHandler,
  totalAmout,
  brutTotalAmout,
  data,
  response,
  isOk,
}) => {
  return (
    <div className=' w-full'>
      <div className=' w-full flex items-center space-x-2 mb-4'>
        <div className=' w-full flex justify-end'>
          <h3 className=' font-bold text-[1rem] md:text-[1.2rem]'>Commande</h3>
        </div>
      </div>
      <div className=' w-full border-[#95808072]  border-[2.2px] shadow-sm px-4 py-5 rounded-md'>
        <h3 className=' font-bold text-[1rem] md:text-[1.1rem] mb-5'>
          Detail de la Commande
        </h3>
        <ul className=' mt-3 flex-col flex space-y-3 w-full border-b-[#dbdbdb] border-b-[3px] pb-4'>
          <li className=' w-full flex items-center justify-between font-semibold text-[0.8rem] md:text-[0.9rem]'>
            <span>Prix</span>
            <span>
              {/* {cartItems?.normalPrice?.toLocaleString().replace(/\s/g, ',')}{' '} */}
              {priceFormatter(Number(brutTotalAmout))} FCFA
            </span>
          </li>
          <li className=' text-[0.8rem] md:text-[0.9rem] w-full flex items-center justify-between font-semibold'>
            <span>Réduction</span>
            <span>
              {/* {cartItems?.reduction?.toLocaleString().replace(/\s/g, ',')} */}
              - {priceFormatter(Number(data?.product?.reduction))} FCFA
            </span>
          </li>
          <li className=' text-[0.8rem] md:text-[0.9rem] w-full flex items-center justify-between font-semibold'>
            <span>Livraison</span>
            <span className='text-[#114cb3]'>
              {Number(data?.product?.shippingFee) > 0
                ? Number(data?.product?.shippingFee)
                : 'Gratuit'}
            </span>
          </li>
        </ul>
        <div className=' flex items-center justify-between py-3'>
          <span className=' text-[1rem] md:text-[1.1rem] font-bold uppercase'>
            Total
          </span>
          <span className=' text-[1rem] md:text-[1.1rem] font-bold uppercase text-[#d60e0e]'>
            {priceFormatter(Number(totalAmout))} FCFA
          </span>
        </div>

        <button
          disabled={response.isPending || !isOk}
          onClick={submitHandler}
          className={`rounded-md ${
            response.isPending || !isOk ? 'bg-[#98a2b2]' : 'bg-[#114cb3]'
          }  w-full py-3 text-white text-[0.9rem] md:text-[1rem] font-medium`}
        >
          {response.isPending ? (
            <ClipLoader color='#36d7b7' />
          ) : (
            ' Finalisez votre commande'
          )}
        </button>
      </div>
    </div>
  );
};

export default ProductSummary;
