import React from 'react';
import PagesHeader from '../utilities/PagesHeader';

const TermeCondiontion = () => {
  return (
    <main>
      <PagesHeader
        images={
          'https://images.unsplash.com/photo-1558402955-dabff43cb9c0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        }
        pageTitle={"Conditions d'utilisation"}
      />
      <div className=' w-[80%] mx-auto'>
        <h2 className=' text-[1.4rem] font-semibold py-2'>1. Introduction</h2>
        <p className=' text-[1.05rem]'>
          Bienvenue sur notre site. En accédant à ce site, vous acceptez d'être
          lié par ces conditions d'utilisation, toutes les lois et règlements
          applicables, et vous acceptez d'être responsable du respect des lois
          locales applicables. Si vous n'êtes pas d'accord avec l'une de ces
          conditions, vous êtes interdit d'utiliser ou d'accéder à ce site. Le
          matériel contenu dans ce site est protégé par les lois applicables sur
          le droit d'auteur et les marques commerciales.
        </p>

        <h2 className=' text-[1.4rem] font-semibold py-2'>
          2. Utilisation de la licence
        </h2>
        <div>
          <p className=' text-[1.05rem]'>
            Il est permis de télécharger temporairement une copie du matériel
            (informations ou logiciels) sur notre site pour un usage personnel
            et non commercial seulement. Ceci est une concession de licence, et
            non un transfert de titre, et sous cette licence, vous ne pouvez pas
            :
          </p>
          <ul className=' py-2 text-[1.05rem] list-decimal ml-8'>
            <li>
              <span>Modifier ou copier le matériel.</span>
            </li>
            <li>
              <span>
                Utiliser le matériel à des fins commerciales, ou pour toute
                exposition publique (commerciale ou non commerciale).
              </span>
            </li>
            <li>
              <span>
                Tenter de décompiler ou de désosser tout logiciel contenu sur
                notre site.
              </span>
            </li>
            <li>
              <span>
                Supprimer tout droit d'auteur ou autre notation de propriété du
                matériel.
              </span>
            </li>
            <li>
              <span>
                Transférer le matériel à une autre personne ou "refléter" le
                matériel sur tout autre serveur.
              </span>
            </li>
          </ul>
        </div>

        <h2 className=' text-[1.4rem] font-semibold py-2'>
          3. Clause de non-responsabilité
        </h2>
        <p className=' text-[1.05rem]'>
          Le matériel sur notre site est fourni "tel quel". Nous ne donnons
          aucune garantie, expresse ou implicite, et déclinons et annulons par
          la présente toutes les autres garanties, y compris, sans limitation,
          les garanties ou conditions implicites de qualité marchande,
          d'adéquation à un usage particulier ou de non-violation de droits de
          propriété intellectuelle ou autre violation des droits. De plus, nous
          ne garantissons ni ne faisons aucune déclaration concernant
          l'exactitude, les résultats susceptibles d'être obtenus ou la
          fiabilité de l'utilisation du matériel sur notre site ou autrement
          relatif à ce matériel ou sur tout site lié à ce site.
        </p>

        <h2 className=' text-[1.4rem] font-semibold py-2'>4. Limitations</h2>
        <p className=' text-[1.05rem]'>
          En aucun cas nous ne serons responsables des dommages spéciaux,
          indirects ou consécutifs ou de tout dommage, qu'il soit en vertu d'un
          contrat, d'une négligence ou autre, découlant de ou en relation avec
          l'utilisation du matériel sur notre site.
        </p>

        <h2 className=' text-[1.4rem] font-semibold py-2'>
          5. Modifications des conditions d'utilisation
        </h2>
        <p className=' text-[1.05rem]'>
          Nous pouvons réviser ces conditions d'utilisation de notre site à tout
          moment sans préavis. En utilisant ce site, vous acceptez d'être lié
          par la version actuelle de ces conditions d'utilisation.
        </p>
      </div>
    </main>
  );
};

export default TermeCondiontion;
