import React, { useEffect, useState } from 'react';
import Layout from '../components/admin/Layout';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import Pagination from '../utilities/utils-components/Pagination';
import { useNavigate } from 'react-router-dom';
import { useCollection } from '../hooks/useCollection';
import { useFireStore } from '../hooks/useFireStore';
import { toast } from 'react-toastify';
import Loader from '../utilities/utils-components/Loader';
import ErreorMessage from '../utilities/utils-components/ErreorMessage';
import Spinner from '../utilities/utils-components/Spinner';
import NotFound from '../utilities/utils-components/NotFound';
import { useMyContext } from '../context/UseContext';

const ServicePage = () => {
  const { user, profil } = useMyContext();
  const { documents, loading, error } = useCollection(
    'products',
    '',
    '',
    '',
    '',
    user.uid
  );
  const { deleteDocument, response } = useFireStore('products');

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);
  const [loding, seLoding] = useState(false);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();
  const clicHandler = () => {
    navigate('/nouveau/service');
  };

  async function deleteHandler(productId) {
    const confirmation = window.confirm('Voulez-vous vraiment supprimer ?');
    if (confirmation) {
      await deleteDocument(productId);
      toast.success('Service supprimer avec success', {
        position: 'top-center',
      });
    }
  }

  useEffect(() => {
    if (response.error) {
      toast.error("Une Erreur s'est produite!", {
        position: 'top-center',
      });
    }
  }, [response.error]);

  useEffect(() => {
    const redirectIfNeeded = () => {
      seLoding(true);
      if (profil && !profil.isSeller && !profil.isAdmin) {
        navigate('/profil/edit');
      }
      seLoding(true);
    };

    redirectIfNeeded();
  }, [profil && profil.isSeller, profil && profil.isAdmin]);

  return (
    <Layout>
      {loding && (
        <div className=' '>
          <div className=' flex w-full justify-end mb-2'>
            <PrimaryButton onClick={clicHandler} text={'Nouveau'} />
          </div>

          {loading ? (
            <div className=' flex w-full items-center justify-center'>
              <Loader />
            </div>
          ) : error ? (
            <ErreorMessage message={'Erreur de recuperation des service'} />
          ) : currentProducts.length === 0 ? (
            <NotFound message='Aucun service trouvé' />
          ) : (
            <>
              <nav className=' bg-[#d6d6d6] py-2 px-4 text-[1.1rem] font-medium'>
                {<Spinner loading={response.isPending} />}
                <ul className=' grid grid-cols-5 md:grid-cols-7'>
                  <li className=' col-span-2'>Services</li>
                  <li className=' hidden md:block col-span-2'>Categorie</li>
                  <li className=' col-span-2'>Prix</li>
                  <li>Action</li>
                </ul>
              </nav>
              <div className='bg-white shadow-sm  '>
                {currentProducts.map((doc, index) => (
                  <ul
                    key={index}
                    className=' grid grid-cols-5 md:grid-cols-7 border-b-2 border-b-[#d6d6d6] items-center p-4'
                  >
                    <li className=' flex-wrap col-span-2 flex items-center  '>
                      <div className=' h-12 w-12 mr-2'>
                        <img
                          src={doc.gallery[0]}
                          alt=''
                          className=' h-full w-full object-cover'
                        />
                      </div>
                      <h2 className=' font-medium text-[0.9rem] md:text-[1rem]'>
                        {doc.title}{' '}
                      </h2>
                    </li>
                    <li className=' hidden md:block md:col-span-2'>
                      <h3 className=' text-[0.95rem] md:text-[1.1rem] font-medium opacity-70'>
                        {doc.companyTypes}
                      </h3>
                    </li>
                    <li className=' col-span-2'>
                      <h3 className=' text-[0.95rem] md:text-[1.1rem] font-semibold opacity-70 '>
                        {doc.price} FCFA
                      </h3>
                    </li>
                    <li className=' flex items-center space-x-2'>
                      <button
                        onClick={() => navigate('/edict/service/' + doc.id)}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='currentColor'
                          className='w-5 h-5 text-[#114cb3]'
                        >
                          <path d='M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z' />
                        </svg>
                      </button>

                      <button onClick={() => deleteHandler(doc.id)}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          className='w-5 h-5 text-red-500'
                        >
                          <path
                            fillRule='evenodd'
                            d='M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                ))}
              </div>
              <Pagination
                documents={documents}
                productsPerPage={productsPerPage}
                currentPage={currentPage}
                paginate={paginate}
              />
            </>
          )}
        </div>
      )}
    </Layout>
  );
};

export default ServicePage;
