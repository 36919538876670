import React from 'react';
import { Link } from 'react-router-dom';
import { priceFormatter } from '../../utils/priceFomatter';
import { useNavigate } from 'react-router-dom';

const ServiceContainer = ({ services }) => {
  const navigate = useNavigate();
  const linkHandler = (service) => {
    return `/${
      service.companyTypes === 'Électroménagère' ||
      service.companyTypes === 'Vêtement et marque' ||
      service.companyTypes === 'Vente de vivrier' ||
      service.companyTypes === 'Électronique' ||
      service.companyTypes === 'Informatique' ||
      service.companyTypes === 'Appareil France au revoir'
        ? 'produit'
        : 'service'
    }/detail/${service.id}`;
  };

  return (
    <div className={`w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto`}>
      <div className=' grid-container grid '>
        {services.map((service) => (
          <Link
            key={service.id}
            to={linkHandler(service)}
            className=' bg-[#dfdfdf]  relative shadow-md overflow-hidden'
          >
            <img
              src={service.gallery[0]}
              alt=''
              className='h-[25vh] w-full object-cover'
            />
            <div className='px-6 py-3 mb-10 '>
              <div className='flex items-center justify-between'>
                <h1 className=' font-bold text-lg '>{service?.title}</h1>
              </div>
              <p className=' mt-1 text-[1rem] font-medium'>
                {service.description.length > 60
                  ? service.description.substring(0, 60) + '...'
                  : service.description}
              </p>
              {/* <div className=' w-full mt-6  mb-10 relative'>
                <div className=' -left-6 absolute -top-2 w-[118%] h-[2px] shadow-sm bg-gray-300'></div>
              </div> */}
            </div>

            <div className='  px-2 flex items-center justify-between py-1 right-0 left-0 border-t-2 border-t-gray-300 font-semibold absolute bottom-0'>
              <span className=' '>{priceFormatter(service.price)} FCFA</span>
              <button
                onClick={() => navigate(linkHandler(service))}
                className=' text-white bg-[#b03923] px-4 py-[0.35rem] rounded-md text-[0.95rem]'
              >
                Details
              </button>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ServiceContainer;
