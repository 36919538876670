import React from 'react';
import { Link } from 'react-router-dom';
import Social from './utils-components/Social';
import { useMyContext } from '../context/UseContext';
import { useFooterLink } from '../hooks/useFooterLink';

const Footer = () => {
  const { user, profil } = useMyContext();
  const { Links } = useFooterLink(user);
  return (
    <footer>
      <nav className=' gap-4 w-[95%] xl:w-[90%] 2xl:w-[80%] grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 mx-auto py-8'>
        <div>
          <h3 className='  font-semibold text-[1rem] lg:text-[1.2rem] mb-2'>
            Communauté{' '}
          </h3>
          <Social />
        </div>
        {Links.map((footerLink, index) => (
          <ul key={index}>
            <h3 className='  font-semibold text-[1rem] lg:text-[1.2rem] mb-2'>
              {footerLink.title}
            </h3>
            {footerLink.link.map((link, index) => (
              <li key={index} className=' flex flex-col'>
                <Link
                  to={link.url}
                  className=' hover:text-[#114cb3] text-[1rem] lg:text-[1.1rem]  font-medium text-[#605f5f]'
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        ))}
        <div>
          <h3 className='  font-semibold text-[1rem] lg:text-[1.2rem] mb-2'>
            Souscrivez a notre newsletter
          </h3>
          <input
            type='email'
            className='w-full outline-none bg-transparent p-2 border-2 rounded-md border-gray-400'
            placeholder='Entrer votre email'
          />
          <button className='bg-[#114cb3] py-2 px-8 text-white mt-2 rounded-md'>
            Souscrire
          </button>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
