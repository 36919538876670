import React from 'react';

const SectionTitle = ({ text }) => {
  return (
    <div className=' flex justify-center items-center'>
      <h2 className=' text-[1.4rem] md:text-[1.6rem] lg:text-[2rem] py-2'>
        {text}
      </h2>
    </div>
  );
};

export default SectionTitle;
