import React, { useState } from 'react';
import { CompanyHeader } from '../components/Company/CompanyHeader';
import GridContainer from '../components/Company/GridContainer';
import Pagination from '../utilities/utils-components/Pagination';
import { useData } from '../data/useData';
import { useCollection } from '../hooks/useCollection';
import Loader from '../utilities/utils-components/Loader';
import { companyTypes, countries } from '../data/data';
import ErreorMessage from '../utilities/utils-components/ErreorMessage';
import NotFound from '../utilities/utils-components/NotFound';

const Company = () => {
  const { services } = useData();

  const [countrieSelected, setCountrieSelected] = useState(countries[0]);
  const [types, setTypes] = useState(companyTypes[0]);
  let isSeller = true;

  const [country, setCountry] = useState('');
  const [company, setCompany] = useState('');
  const [title, setTitle] = useState('');

  const [search, setSearch] = useState('');

  const { documents, loading, error } = useCollection(
    'users',
    country,
    company,
    title,
    '',
    '',
    isSeller
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function submitSearch(e) {
    e.preventDefault();
    setCountry(
      countrieSelected.name === 'Toutes les localités'
        ? ''
        : countrieSelected.name
    );
    setCompany(types.name === "Types d'entreprises" ? '' : types.name);
    setTitle(search);
  }

  return (
    <>
      <main>
        <CompanyHeader
          setCountrieSelected={setCountrieSelected}
          countrieSelected={countrieSelected}
          setTypes={setTypes}
          types={types}
          submitSearch={submitSearch}
          setSearch={setSearch}
        />

        {loading ? (
          <div className=' h-40'>
            <Loader />
          </div>
        ) : error ? (
          <ErreorMessage message={'Erreur de recuperation des service'} />
        ) : currentProducts.length === 0 ? (
          <div className=' h-[100px] pt-4 w-[60%] mx-auto justify-center items-center  flex  text-center'>
            <NotFound message='Aucun service trouvé' />
          </div>
        ) : (
          <>
            <GridContainer
              companies={currentProducts}
              mb='95%'
              tb='90%'
              width={'80%'}
            />
            <Pagination
              documents={documents}
              productsPerPage={productsPerPage}
              currentPage={currentPage}
              paginate={paginate}
            />
          </>
        )}
      </main>
    </>
  );
};

export default Company;
