import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <main className='w-[60%]  mx-auto flex  justify-center  items-center flex-col'>
      <h1 className=' font-bold text-[3rem]'>404</h1>
      <h2 className=' text-[1.4rem]'>Page non trouvée</h2>
      <p className=' text-[1.1rem]'>
        Désolé, la page que vous recherchez n'existe pas.
      </p>

      <Link className=' bg-[#114cb3] mt-2 px-4 py-2 text-white' to='/'>
        Retour à l'accueil
      </Link>
    </main>
  );
};

export default NotFound;
