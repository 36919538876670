import React from 'react';
import Header from '../components/productDetails/Header';
import { ProductContent } from '../components/productDetails/ProductContent';
import Description from '../components/serviceDetails/Description';
import Comment from '../components/serviceDetails/Comment';
import { useParams } from 'react-router-dom';
import { useDocRelated } from '../hooks/useDocRelated';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import Related from '../components/serviceDetails/Related';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#1e4ea0',
  // width: '100%',
};

const ProductDetails = () => {
  const params = useParams();
  const { id } = params;

  const { docResponse } = useDocRelated('products', 'products', id);

  const { seller, service, document, isPending, error, success } = docResponse;

  return (
    <>
      {isPending ? (
        <div className=' h-full w-full flex justify-center items-center'>
          <ClimbingBoxLoader
            color='#1e4ea0'
            loading={true}
            cssOverride={override}
            size={20}
            data-testid='loader'
          />
        </div>
      ) : (
        <main>
          <Header service={service} />
          <ProductContent service={service} seller={seller} />
          <div className=' w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto '>
            <Description service={service} />
            <Comment service={service} seller={seller} id={id} />
          </div>
          <Related services={document} />
        </main>
      )}
    </>
  );
};

export default ProductDetails;
