import React from 'react';
import PagesHeader from '../utilities/PagesHeader';
import Apropos from '../components/quiSommesNous/Apropos';
import Expertise from '../components/Home/Expertise';
import ExpertiseCard from '../components/Home/ExpertiseCard';
import { expertiseData } from '../data/data';
import { Link, useNavigate } from 'react-router-dom';
import NotreEquipe from '../components/quiSommesNous/NotreEquipe';
import OurExpertise from '../components/quiSommesNous/OurExpertise';
import StartNow from '../utilities/utils-components/StartNow';

const QuiSommesNous = () => {
  const navigate = useNavigate();
  const clikHandler = () => {
    navigate('/inscription');
  };
  return (
    <main>
      <PagesHeader
        images={
          'https://plus.unsplash.com/premium_photo-1661782903310-282308de4b2b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        }
        pageTitle={'Qui Sommes nous?'}
      />
      <div className=' w-[95%]  xl:w-[80%] mx-auto'>
        <Apropos />
        <NotreEquipe />
        <div className='mt-6'>
          <h2 className='text-[1.1rem] md:text-[1.6rem] font-semibold capitalize'>
            Notre Domaine d'expertise
          </h2>
          <div className='expert-container '>
            {expertiseData.map((item, index) => (
              <OurExpertise
                key={index}
                index={index}
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </div>
      </div>
      <StartNow
        text={"Voulez vous nous rejoindre et commencé l'aventure"}
        textButton={'Demarrer maintenant'}
        clikHandler={clikHandler}
      />
    </main>
  );
};

export default QuiSommesNous;
