import { useMyContext } from './context/UseContext';
import AppRoutes from './routes/AppRoutes';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#1e4ea0',
  // width: '100%',
};

function App() {
  const { authIsReady } = useMyContext();
  return (
    <>
      {authIsReady ? (
        <>
          <AppRoutes />
          <ToastContainer />
        </>
      ) : (
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClimbingBoxLoader
            color='#1e4ea0'
            loading={true}
            cssOverride={override}
            size={20}
            data-testid='loader'
          />
        </div>
      )}
    </>
  );
}

export default App;
