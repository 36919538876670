import React, { useEffect, useState } from 'react';
import SearchBar from '../../utilities/SearchBar';
import { useData } from '../../data/useData';
import MobileSearchBar from '../../utilities/MobileSearchBar';

const HeaderCarousel = () => {
  const { carousel } = useData();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % carousel.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  function dots() {
    return (
      <div className='flex justify-center mt-4'>
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className={`h-3 w-3 rounded-full mx-2 cursor-pointer ${
              activeIndex === index ? 'bg-white' : 'bg-gray-400'
            }`}
          ></div>
        ))}
      </div>
    );
  }

  return (
    <div className=' h-[40vh] lg:h-[60vh] relative w-full'>
      <div className='absolute z-10 left-0 top-0 bottom-0 right-0 bg-[#00000055]'></div>
      <div className='absolute z-0 left-0 top-0 bottom-0 right-0 object-cover'>
        <img
          src={carousel[activeIndex]}
          alt=''
          className=' h-full w-full object-cover'
        />
      </div>

      <div className='w-[95%] xl:w-[90%] 2xl:w-[65%]  absolute  z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
        <h1 className=' text-[1.2rem] md:text-[1.5rem] lg:text-[2rem] text-white mb-2'>
          Explorez les entreprises près de chez vous!
        </h1>
        <MobileSearchBar />
        <SearchBar />
      </div>
      <div className='absolute z-40 bottom-0 left-1/2  mx-2  -translate-x-1/2 -translate-y-1/2 flex justify-between'>
        {dots()}
      </div>
    </div>
  );
};

export default HeaderCarousel;
