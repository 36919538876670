import React from 'react';
import Question from '../components/faqs/Question';
import { useData } from '../data/useData';
import PageHeader from '../utilities/PageHeader';
import StartNow from '../utilities/utils-components/StartNow';
import { useNavigate } from 'react-router-dom';

const FaqPage = () => {
  const navigate = useNavigate();
  const clikHandler = () => {
    navigate('/contactez-nous');
  };
  const { faqs } = useData();
  return (
    <main>
      <PageHeader />
      <div className='w-[85%] md:w-[95%] lg:w-[80%] mx-auto py-2'>
        <section>
          <h2 className=' text-[1.4rem] md:text-[1.6rem]'>
            Questions générales
          </h2>
          {faqs.map((faq, index) => (
            <Question faq={faq} key={index} index={index} />
          ))}
        </section>
      </div>
      <StartNow
        text={'Avez-vous des questions?'}
        textButton={'Contactez-nous'}
        clikHandler={clikHandler}
      />
    </main>
  );
};

export default FaqPage;
