import React from 'react';
import HeaderCarousel from '../components/Home/HeaderCarousel';
import Expertise from '../components/Home/Expertise';
import CallToAction from '../components/Home/CallToAction';
import GridContainer from '../components/Company/GridContainer';
import SectionTitle from '../utilities/SectionTitle';
import { useData } from '../data/useData';
import { Navigate, useNavigate } from 'react-router-dom';
import { useCollection } from '../hooks/useCollection';

function Home() {
  const { services } = useData();
  let isSeller = true;
  const { documents, loading, error } = useCollection(
    'users',
    '',
    '',
    '',
    '',
    '',
    isSeller
  );

  const navigate = useNavigate();

  return (
    <main className='  '>
      <HeaderCarousel />
      <Expertise />
      <div className=' bg-[#f1f1f1] mb-10 py-4'>
        <div className='w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto text-center'>
          <SectionTitle text='Entreprise populaire selectionnée pour vous' />
        </div>

        <GridContainer
          companies={documents.slice(0, 8)}
          mb='95%'
          tb='90%'
          width={'80%'}
        />
        <div className=' flex items-center justify-center py-2'>
          <button
            onClick={() => navigate('/entreprise')}
            className=' bg-[#d60e0e] hover:bg-[#c62525]  px-8 py-2 rounded-md text-white'
          >
            Voir plus
          </button>
        </div>
      </div>

      <CallToAction />
    </main>
  );
}

export default Home;
