import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Detailheader from '../components/serviceDetails/Detailheader';
import DescriptionSection from '../components/serviceDetails/DescriptionSection';
import Related from '../components/serviceDetails/Related';
import Comment from '../components/serviceDetails/Comment';
import { useParams } from 'react-router-dom';
import { useDocRelated } from '../hooks/useDocRelated';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useServiceDoc } from '../hooks/useServiceDoc';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#1e4ea0',
  // width: '100%',
};

const ServiceDetails = () => {
  const params = useParams();
  const { id } = params;
  const [bookingDays, setBookingDays] = useState([]);
  const [isIncludes, setIsIncludes] = useState(false);

  const { docResponse } = useServiceDoc('products', 'products', id, 'orders');

  const { seller, service, document, isPending, error, success, orders } =
    docResponse;

  return (
    <>
      {isPending ? (
        <div className=' h-full w-full flex justify-center items-center'>
          <ClimbingBoxLoader
            color='#1e4ea0'
            loading={true}
            cssOverride={override}
            size={20}
            data-testid='loader'
          />
        </div>
      ) : (
        <main>
          <Detailheader
            isIncludes={isIncludes}
            bookingDays={bookingDays}
            seller={seller}
            service={service}
          />
          <DescriptionSection
            isIncludes={isIncludes}
            setIsIncludes={setIsIncludes}
            orders={orders}
            setBookingDays={setBookingDays}
            service={service}
            seller={seller}
            id={id}
          />
          <Related services={document} />
        </main>
      )}
    </>
  );
};

export default ServiceDetails;
