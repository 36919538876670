import React, { useState } from 'react';
import ProductStar from '../../utilities/utils-components/ProductStar';
import PrimaryButton from '../../utilities/utils-components/PrimaryButton';
import { Link, useNavigate } from 'react-router-dom';
import { priceFormatter } from '../../utils/priceFomatter';

export const ProductContent = ({ service, seller }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [qty, setQty] = useState(1);

  const navigate = useNavigate();

  function clickHandler() {
    const totalAmout =
      Number(service.price) * qty + Number(service.shippingFee);
    const brutTotalAmout = Number(service.normalePrice) * qty;
    const reduction =
      service.normalePrice > service.price
        ? (service.normalePrice - service.price) * qty
        : 0;

    const product = {
      product: { ...service, totalAmout, qty, reduction, brutTotalAmout },
      seller: { name: seller.userName, id: seller.id },
    };
    localStorage.setItem('product', JSON.stringify(product));
    navigate('/addresse/client');
  }

  return (
    <div className=' w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto grid-cols-1 grid md:grid-cols-2 my-8 gap-6'>
      <div>
        <div className=' w-full h-[350px]  md:h-[600px] '>
          <img
            src={service.gallery[currentIndex]}
            alt=''
            className=' w-full h-full rounded-md object-cover'
          />
        </div>
        <div className=' flex space-x-4 my-5'>
          {service.gallery.map((image, index) => (
            <button
              onClick={() => setCurrentIndex(index)}
              key={index}
              className=' h-16 md:h-28 w-24 rounded-md'
            >
              <img
                src={image}
                alt=''
                className=' w-full h-full rounded-md object-cover'
              />
            </button>
          ))}
        </div>
      </div>
      <div>
        <div className=' border-b-[3px] border-b-[#b3b3b39b] pb-3'>
          <h1 className=' font-semibold text-[1.3rem] md:text-[1.6rem]'>
            {service.title}
          </h1>
          <div className=' items-center flex space-x-2'>
            <ProductStar rating={Number(service.star)} />
            <span className=' text-[1.2rem] opacity-70'>
              {service.comments.length} commentaire(s)
            </span>
          </div>
          <p>{service.shortdescription}</p>
        </div>
        <div>
          <ul className=' my-2 flex flex-col space-y-1'>
            {service.marque && (
              <li className=' flex items-center space-x-2'>
                <span className=' font-semibold text-[1.1rem] md:text-[1.2rem]'>
                  Marque:
                </span>
                <span className='text-[1.1rem] md:text-[1.2rem]'>
                  {service.marque}
                </span>
              </li>
            )}
            {service.condition && (
              <li className=' flex items-center space-x-2'>
                <span className=' font-semibold text-[1.1rem] md:text-[1.2rem]'>
                  Condition:
                </span>
                <span className='text-[1.1rem] md:text-[1.2rem]'>
                  {service.condition}
                </span>
              </li>
            )}
          </ul>
        </div>
        <div>
          <div className=' space-x-4'>
            <span className='  line-through text-[#0101017a] text-[1.1rem]  font-semibold'>
              {priceFormatter(service.normalePrice)} FCFA
            </span>
            <span className='text-[#1e4ea0] text-[1rem]  md:text-[1.3rem]  font-semibold'>
              {priceFormatter(service.price)} FCFA
            </span>
          </div>
          <span className=' md:text-[1rem] text-[0.9rem] font-semibold'>
            {service.livraison === 'Livraison facturée'
              ? `Frais de livraison ${service.shippingFee} (${service.deleveryTime}) `
              : `${service.livraison} (${service.deleveryTime})`}
          </span>
          <div className=' flex items-center my-4 space-x-2'>
            <div className=' border-2 border-gray-300 flex items-center'>
              <div className='  w-10 px-2'>
                <span>{qty}</span>
              </div>

              <div className=' flex flex-col'>
                <button
                  onClick={() => {
                    setQty((qty) => qty + 1);
                  }}
                  className=' border-2 border-gray-300'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='w-5 h-5'
                  >
                    <path
                      fillRule='evenodd'
                      d='M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
                <button
                  onClick={() => {
                    if (qty === 1) {
                      return;
                    } else {
                      setQty((qty) => qty - 1);
                    }
                  }}
                  className=' border-2 border-gray-300'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='w-5 h-5'
                  >
                    <path
                      fillRule='evenodd'
                      d='M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </div>
            </div>
            <PrimaryButton onClick={clickHandler} text={'Acheter'} />
          </div>
          <div>
            <h1 className='text-[#0101017a] text-[1.1rem]  font-semibold '>
              Entreprise:
            </h1>
            <Link
              to={`/detail-entreprise/${seller.id}`}
              className=' font-semibold'
            >
              {seller.userName}
            </Link>
            <div className=' flex space-x-2 mt-2'>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                to={seller.facebook}
                className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
              >
                <svg
                  className='h-3 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 320 512'
                  fill='#FFFFFF'
                >
                  <path d='M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z' />
                </svg>
              </Link>
              <Link
                to={seller.instagram}
                target='_blank'
                rel='noopener noreferrer'
                className=' rounded-full  w-[35px] h-[35px] flex justify-center items-center bg-[#ea0b86]'
              >
                <svg
                  className='h-3 text-white'
                  fill='#FFFFFF'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 448 512'
                >
                  <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z' />
                </svg>
              </Link>
              <Link
                to={seller.linkedin}
                target='_blank'
                rel='noopener noreferrer'
                className=' rounded-full w-[35px] h-[35px] flex justify-center items-center bg-[#114cb3]'
              >
                <svg
                  className='h-3 text-white'
                  fill='#FFFFFF'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 448 512'
                >
                  <path d='M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z' />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
