import React from 'react';
import { Link } from 'react-router-dom';

const GridContainer = ({ mb, tb, companies, width }) => {
  return (
    <div className={`w-[${mb}] xl:w-[${tb}] 2xl:w-[${width}]  mx-auto`}>
      <div className=' grid-container grid '>
        {companies.map((doc) => (
          <Link
            key={doc.id}
            to={`/detail-entreprise/${doc.id}`}
            className=' bg-[#dfdfdf] shadow-md'
          >
            <img
              src={doc.banner[0]}
              alt=''
              className='h-[16vh] w-full object-cover'
            />
            <div>
              <div className=' rounded-md flex-col flex justify-center items-center relative z-30 shadow-md w-[5.5rem] h-20 ml-6 -mt-9 bg-white'>
                <img
                  src={`https://flagsapi.com/${doc.contrieCode}/flat/64.png`}
                />
                <h3 className=' font-semibold  text-xs'>{doc.countries}</h3>
              </div>
            </div>
            <div className='px-6 py-3 '>
              <div className='flex items-center justify-between'>
                <h1 className=' font-bold text-lg '>
                  {doc.userName.substring(0, 1).toUpperCase() +
                    doc.userName.substring(1)}
                </h1>
                <div className=' flex items-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24 '
                    fill={` ${Number(doc.star) === 0 ? '#adadad' : '#e5c304'} `}
                    className={`w-5 h-5`}
                  >
                    <path
                      fillRule='evenodd'
                      d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z'
                      clipRule='evenodd'
                    />
                  </svg>
                  <span className=' font-bold'>{doc.star}</span>
                </div>
              </div>
              <div className=' space-x-2 mt-2 flex items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-5 h-5'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z'
                  />
                </svg>

                <span>{doc.companyType} </span>
              </div>
              <p className=' mt-1 text-[1rem] font-medium'>
                {doc?.bio?.length > 90
                  ? doc?.bio?.substring(0, 90) + '...'
                  : doc.bio}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GridContainer;
