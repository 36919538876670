import React from 'react';

const StartNow = ({ clikHandler, text, textButton }) => {
  return (
    <div className=' bg-[#114cb3] mt-8 py-4'>
      <div className=' w-[85%] md:w-[95%] lg:w-[80%] mx-auto flex-col md:flex-row flex items-center justify-between'>
        <span className=' font-medium text-white text-center md:text-start text-[1rem] md:text-[1.2rem]'>
          {text}
        </span>
        <button
          onClick={clikHandler}
          className=' bg-black text-white px-6 py-3 rounded-3xl'
        >
          {textButton}
        </button>
      </div>
    </div>
  );
};

export default StartNow;
