import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../utilities/utils-components/PrimaryButton';
import { useFireStore } from '../../hooks/useFireStore';
import { useMyContext } from '../../context/UseContext';
import { useNavigate } from 'react-router-dom';
import { useDocument } from '../../hooks/useDocument';
import { toast } from 'react-toastify';
import BarLoader from 'react-spinners/BarLoader';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#4b02a4',
  width: '100%',
};

const Comment = ({ id, service }) => {
  const [selectedStars, setSelectedStars] = useState(0);
  const maxStars = 5;
  const noneSelected = maxStars - selectedStars;

  const { user, profil } = useMyContext();

  const { addComment, response } = useFireStore('products', 'users');

  const {
    document: DOC,
    isPending: docPending,
    error: docError,
    success: docSuccess,
  } = response;

  const navigate = useNavigate();
  const [text, setText] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();

    if (user) {
      const existingCommentIndex = service.comments.findIndex(
        (c) => c.uid === user.uid
      );

      const existingUserCommentIndex = profil.comments.findIndex(
        (c) => c.user === user.uid
      );

      if (existingCommentIndex !== -1) {
        service.comments[existingCommentIndex] = {
          rate: Number(selectedStars),
          text: text,
          uid: user.uid,
          profil: profil.photo,
          name: profil.userName,
          createdAt: Date.now(),
        };

        profil.comments[existingUserCommentIndex] = {
          rate: Number(selectedStars),
          user: user.uid,
          createdAt: Date.now(),
        };
      } else {
        service.comments.push({
          rate: Number(selectedStars),
          text: text,
          uid: user.uid,
          profil: profil.photo,
          name: profil.userName,
          createdAt: Date.now(),
        });
        profil.comments.push({
          rate: Number(selectedStars),
          user: user.uid,
          createdAt: Date.now(),
        });
      }

      const totalRating = service.comments.reduce(
        (acc, cur) => acc + cur.rate,
        0
      );

      const newAverageRating = (totalRating / service.comments.length).toFixed(
        1
      );

      const totalUserRating = profil.comments.reduce(
        (acc, cur) => acc + cur.rate,
        0
      );

      const userAverageRating = (
        totalUserRating / profil.comments.length
      ).toFixed(1);

      const comments = {
        star: newAverageRating,
        comments: service.comments,
      };

      const userComments = {
        star: userAverageRating,
        comments: profil.comments,
      };

      await addComment(id, user.uid, comments, userComments);
    } else {
      navigate('/connexion');
    }
  };

  useEffect(() => {
    setText('');
    setSelectedStars(0);
  }, [docSuccess]);

  useEffect(() => {
    if (response.success) {
      toast.success('Commentaire ajouté avec succès', {
        position: 'top-center',
      });
    }
  }, [response.success]);

  const handleStarHover = (starIndex) => {
    if (selectedStars === 1) {
      setSelectedStars(0);
    } else {
      setSelectedStars(starIndex);
    }
  };

  const handleStarClick = (starIndex) => {
    setSelectedStars(starIndex);
  };
  return (
    <div className=' mt-5 bg-white p-4 rounded-md'>
      <BarLoader
        color='##1e4ea0'
        loading={response.isPending}
        cssOverride={override}
        size={150}
        data-testid='loader'
      />
      <div>
        <h2 className=' text-[1.1rem] md:text-[1.2rem] font-semibold'>
          Commentaire
        </h2>
        <p>Votre avis nous aide à nous améliorer et à mieux vous servir.</p>
        <div className=' flex items-center my-2'>
          {Array.from({ length: selectedStars }).map((_, index) => (
            <FontAwesomeIcon
              key={index}
              className=' text-yellow-500 cursor-pointer'
              icon={faStar}
              onMouseEnter={() => handleStarHover(index + 1)}
              onClick={() => handleStarClick(index + 1)}
            />
          ))}
          {Array.from({ length: noneSelected }).map((_, index) => (
            <span
              className='cursor-pointer'
              key={index}
              onMouseEnter={() => handleStarHover(selectedStars + index + 1)}
              onClick={() => handleStarClick(selectedStars + index + 1)}
            >
              <svg
                className=' h-[1.05rem] w-[1.05rem]'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 576 512'
                fill='#999999'
              >
                <path d='M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z' />
              </svg>
            </span>
          ))}
        </div>
      </div>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        rows={4}
        className=' my-2 p-2 rounded-md border-2 border-gray-300 resize-none outline-none w-full'
        type='text'
        placeholder='Entrer votre commentaire'
      />
      <PrimaryButton onClick={submitHandler} text={'Ajouter'} />
    </div>
  );
};

export default Comment;
