import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyA6YaNyo774fWMzjK7z9HckxRKVtKtR-uM',
  authDomain: 'preffog-8c3f1.firebaseapp.com',
  projectId: 'preffog-8c3f1',
  storageBucket: 'preffog-8c3f1.appspot.com',
  messagingSenderId: '110363125118',
  appId: '1:110363125118:web:1b9cdfce4a633a9f0eff2f',
};

// inti firebase

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
