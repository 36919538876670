const PagesHeader = ({ pageTitle, images }) => {
  return (
    <div className=' h-[18vh] relative'>
      <div className=' z-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
        <h3 className=' uppercase text-white font-medium w-[150%] text-[1rem] md:text-[1.3rem]'>
          {pageTitle}
        </h3>
      </div>
      <div className=' h-full w-full z-10 bg-[#00000056] absolute'></div>
      <div className=' h-fullb absolute top-0 right-0 left-0 bottom-0 z-0'>
        <img
          src={images}
          alt=''
          className=' h-full w-full object-cover object-top '
        />
      </div>
    </div>
  );
};

export default PagesHeader;
