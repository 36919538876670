import React, { useEffect, useState } from 'react';
import FormInput from '../../utilities/utils-components/FormInput';
import SelectInput from '../../utilities/utils-components/SelectInput';
import { countries, pays } from '../../data/data';

const ShippingDetails = ({ setIsOk, onShippingAdresse }) => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState(pays[0]);
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (
      name !== '' &&
      lastName !== '' &&
      address !== '' &&
      country.name !== 'Pays' &&
      email !== '' &&
      city !== '' &&
      phone !== ''
    ) {
      setIsOk(true);
    } else {
      setIsOk(false);
    }
  }, [name, lastName, address, country, city, phone]);

  onShippingAdresse({ name, lastName, address, country, city, email, phone });
  return (
    <div className=' bg-[#d2d2d2] shadow-md rounded-md p-2 md:p-6 mt-4'>
      <h2 className=' font-semibold mb-3 text-[1.2rem]'>Détails livraison</h2>
      <div className=' flex flex-col space-y-2 md:space-y-0  md:grid md:grid-cols-2  md:gap-5'>
        <FormInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          type='text'
          placeholder={`Entrer votre nom `}
        />
        <FormInput
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          type='text'
          placeholder={`Entrer votre prénom`}
        />
      </div>
      <div className=' mb-5 mt-3 md:mb-5 md:mt-5 '>
        <FormInput
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          type='text'
          placeholder={`Entrer votre addresse`}
        />
      </div>
      <div className=' -mt-2 flex flex-col md:mt-2 space-y-3 md:space-y-0  md:grid md:grid-cols-2  md:gap-5'>
        <SelectInput
          inputEls={pays}
          setSelected={setCountry}
          selected={country}
          bg='#e8e8e8'
          iconColor='text-black'
        />
        <FormInput
          value={city}
          onChange={(e) => setCity(e.target.value)}
          type='text'
          placeholder={`Ville`}
        />
      </div>
      <div className=' mt-4  flex flex-col md:mt-2 space-y-3 md:space-y-0  md:grid md:grid-cols-2  md:gap-5'>
        <FormInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type='text'
          placeholder={`Entrez votre addresse email `}
        />
        <FormInput
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          type='text'
          placeholder={`Entrer votre numéro de téléphone`}
        />
      </div>
    </div>
  );
};

export default ShippingDetails;
