import React, { useEffect, useState } from 'react';
import Layout from '../components/admin/Layout';
import { useCollection } from '../hooks/useCollection';
import { useFireStore } from '../hooks/useFireStore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../utilities/utils-components/Loader';
import ErreorMessage from '../utilities/utils-components/ErreorMessage';
import NotFound from '../utilities/utils-components/NotFound';
import Spinner from '../utilities/utils-components/Spinner';
import Pagination from '../utilities/utils-components/Pagination';

const SellerList = () => {
  const { documents, loading, error } = useCollection('users');
  const { deleteDocument, response } = useFireStore('users');

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(8);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  let currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();
  const clicHandler = () => {
    navigate('/nouvvelle-annonces');
  };

  async function deleteHandler(productId) {
    const confirmation = window.confirm('Voulez-vous vraiment supprimer ?');
    if (confirmation) {
      await deleteDocument(productId);
      toast.success('Annonce supprimer avec success!', {
        position: 'top-center',
      });
    }
  }

  useEffect(() => {
    if (response.error) {
      toast.error("Une Erreur s'est produite!", {
        position: 'top-center',
      });
    }
  }, [response.error]);

  currentProducts.sort((a, b) => {
    if (a.isSellerRequested && b.isSeller) {
      return -1;
    } else if (!a.isSellerRequested && b.isSellerRequested) {
      return 1;
    } else {
      return 0;
    }
  });

  //   const people = [
  //     { name: 'Alice', age: 25 },
  //     { name: 'Bob', age: 30 },
  //     { name: 'Charlie', age: 20 },
  //   ];
  //   people.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Layout>
      {' '}
      <div className=' '>
        {loading ? (
          <div className=' flex w-full items-center justify-center'>
            <Loader />
          </div>
        ) : error ? (
          <ErreorMessage message={'Erreur de recuperation des annonces'} />
        ) : currentProducts.length === 0 ? (
          <NotFound message='Aucun annonce trouvée' />
        ) : (
          <>
            <nav className=' bg-[#d6d6d6] py-2 px-4 text-[1.1rem] font-medium'>
              {<Spinner loading={response.isPending} />}
              <ul className='grid grid-cols-6'>
                <li className='col-span-4'>Utilisateurs</li>
                <li className='col-span-1 flex justify-center'>
                  <span>Demande</span>
                </li>
                <li className='col-span-1 flex justify-end'>
                  <span>Action</span>
                </li>
              </ul>
            </nav>
            <div className='bg-white shadow-sm  '>
              {currentProducts.map((doc, index) => (
                <ul
                  key={index}
                  className=' grid grid-cols-6 border-b-2 border-b-[#d6d6d6] items-center p-4'
                >
                  <li className=' col-span-4 flex items-center  flex-wrap '>
                    {doc.photo ? (
                      <div className=' h-12 w-12 mr-2'>
                        <img
                          src={doc.photo}
                          alt='image'
                          className=' h-full w-full object-cover'
                        />
                      </div>
                    ) : (
                      <div className='  mr-2  flex justify-center items-center  border-white  '>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'
                          className=' h-12 w-12'
                          fill='#727272'
                        >
                          <path d='M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' />
                        </svg>
                      </div>
                    )}

                    <h2 className=' font-medium text-[1rem]'>
                      {doc.userName}{' '}
                    </h2>
                  </li>
                  <li className='col-span-1 flex justify-center'>
                    {doc.isSeller ? (
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 448 512'
                          className='w-8 h-8 text-lime-400'
                          fill='#02ce1d'
                        >
                          <path d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z' />
                        </svg>
                      </span>
                    ) : doc.isSellerRequested && !doc.isSeller ? (
                      <span className=' text-[1.1rem] text-red-600'>
                        encours
                      </span>
                    ) : (
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='currentColor'
                          className='w-8 h-8'
                        >
                          <path
                            fill-rule='evenodd'
                            d='M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z'
                            clip-rule='evenodd'
                          />
                        </svg>
                      </span>
                    )}
                  </li>

                  <li className=' flex items-center justify-end space-x-2'>
                    <button onClick={() => navigate('/admin/users/' + doc.id)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        className='w-5 h-5 text-[#114cb3]'
                      >
                        <path d='M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z' />
                        <path
                          fillRule='evenodd'
                          d='M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </button>

                    <button onClick={() => deleteHandler(doc.id)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        className='w-5 h-5 text-red-500'
                      >
                        <path
                          fillRule='evenodd'
                          d='M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </button>
                  </li>
                </ul>
              ))}
            </div>
            <Pagination
              documents={documents}
              productsPerPage={productsPerPage}
              currentPage={currentPage}
              paginate={paginate}
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default SellerList;
