import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ShippingProduct = ({ setIsQty, data, qty, setQty }) => {
  return (
    <div>
      <div className=' grid grid-cols-4 md:grid-cols-3 gap-4'>
        <div className=' col-span-4 md:col-span-1'>
          <img
            src={data?.product?.gallery[0]}
            alt=''
            className=' h-[300px] object-cover w-full rounded-md'
          />
        </div>
        <div className=' col-span-4 md:col-span-2'>
          <h2 className=' font-semibold mb-3 text-[1rem]'>
            {data?.product?.title}
          </h2>
          <span className=' opacity-70'>{data?.seller?.name}</span>

          {data?.isService ? (
            ''
          ) : (
            <>
              <div className=' flex items-center my-4 space-x-2'>
                <div className=' border-2 border-gray-300 flex items-center'>
                  <div className='  w-10 px-2'>
                    <span>{qty}</span>
                  </div>

                  <div className=' flex flex-col'>
                    <button
                      onClick={() => {
                        setQty((qty) => qty + 1);
                        setIsQty(true);
                      }}
                      className=' border-2 border-gray-300'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          fillRule='evenodd'
                          d='M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        if (qty === 1) {
                          return;
                        } else {
                          setQty((qty) => qty - 1);
                          setIsQty(true);
                        }
                      }}
                      className=' border-2 border-gray-300'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          fillRule='evenodd'
                          d='M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShippingProduct;
