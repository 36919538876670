import React, { useEffect, useState } from 'react';
import Layout from '../components/admin/Layout';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import Pagination from '../utilities/utils-components/Pagination';
import { useCollection } from '../hooks/useCollection';
import { useFireStore } from '../hooks/useFireStore';
import { useMyContext } from '../context/UseContext';
import { priceFormatter } from '../utils/priceFomatter';
import { useNavigate } from 'react-router-dom';
import Loader from '../utilities/utils-components/Loader';
import ErreorMessage from '../utilities/utils-components/ErreorMessage';
import NotFound from '../utilities/utils-components/NotFound';
const services = [
  {
    imageUrl: [
      'https://images.unsplash.com/photo-1664575602276-acd073f104c1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    title: 'Good fashion',
    categorie: 'Restaurant',
    price: '100.000',
  },
  {
    imageUrl: [
      'https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    title: 'Beauty Salon',
    categorie: 'Beauté',
    price: '80.000',
  },
  {
    imageUrl: [
      'https://images.unsplash.com/photo-1552581234-26160f608093?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    title: 'Fast Delivery',
    categorie: 'Livraison',
    price: '50.000',
  },
  {
    imageUrl: [
      'https://images.unsplash.com/photo-1530099486328-e021101a494a?q=80&w=2147&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    title: 'Tech Repair',
    categorie: 'Services techniques',
    price: '120.000',
  },
  {
    imageUrl: [
      'https://images.unsplash.com/photo-1531482615713-2afd69097998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    title: 'Fitness Center',
    categorie: 'Fitness',
    price: '150.000',
  },
  {
    imageUrl: [
      'https://images.unsplash.com/photo-1664575599618-8f6bd76fc670?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ],
    title: 'Home Cleaning',
    categorie: 'Services à domicile',
    price: '90.000',
  },
];

const Orders = () => {
  const { user, profil } = useMyContext();
  const [loding, seLoding] = useState(false);

  useEffect(() => {
    const redirectIfNeeded = () => {
      seLoding(true);
      if (profil && !profil.isSeller && !profil.isAdmin) {
        navigate('/profil/edit');
      }
      seLoding(true);
    };

    redirectIfNeeded();
  }, [profil && profil.isSeller, profil && profil.isAdmin]);

  const navigate = useNavigate();

  const { documents, loading, error } = useCollection(
    'orders',
    '',
    '',
    '',
    user.uid
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(5);

  const indexOfLastProduct = currentPage * productsPerPage;

  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = documents.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Layout ordercard={'ordercard'}>
      {loding && (
        <>
          {loading ? (
            <Loader />
          ) : error ? (
            <ErreorMessage message={'Erreur de recuperation des commandes'} />
          ) : currentProducts.length === 0 ? (
            <NotFound message='Aucune commande commande trouvé' />
          ) : (
            <>
              <div className='  overflow-x-auto w-full '>
                <nav className=' bg-[#d6d6d6] w-[1100px] lg:w-auto py-2 px-4 text-[1.1rem]  font-medium'>
                  <ul className=' grid orders grid-cols-7'>
                    <li className=' col-span-2'>Services</li>
                    <li className=' col-span-2'>Montant</li>
                    <li className=' col-span-2 '>clients</li>
                    <li className='flex justify-center'>Action</li>
                  </ul>
                </nav>
                <div className='bg-white shadow-sm w-[1100px] lg:w-auto  '>
                  {currentProducts.map((service, index) => (
                    <ul
                      key={index}
                      className=' grid orders grid-cols-7 border-b-2 border-b-[#d6d6d6] items-center p-4'
                    >
                      <li className=' col-span-2 flex items-center flex-wrap '>
                        <div className=' h-20 w-16'>
                          <img
                            src={service.productImage}
                            alt=''
                            className=' h-full w-full rounded-md object-cover'
                          />
                        </div>
                        <h2 className=' font-medium text-[0.9rem] md:text-[1rem]'>
                          {service.title}{' '}
                        </h2>
                      </li>
                      <li className=' col-span-1'>
                        <h3 className=' text-[0.9rem] md:text-[1.1rem] font-semibold opacity-70 '>
                          {priceFormatter(Number(service.totalAmout))} FCFA
                        </h3>
                      </li>
                      <li className=' text-[0.8rem] col-span-3 flex flex-col'>
                        <span className='  flex space-x-1'>
                          <span className=' font-semibold'>Nom: </span>
                          <span>
                            {' '}
                            {service.shippingAddresse.name +
                              ' ' +
                              service.shippingAddresse.lastName}{' '}
                          </span>
                        </span>
                        <span lassName='  flex space-x-1'>
                          <span className=' font-semibold'>Numéro: </span>
                          <span>{service.shippingAddresse.phone} </span>
                        </span>
                        <span lassName=' flex space-x-1'>
                          <span className=' font-semibold'>Adresse: </span>
                          <span>abidjan</span>
                        </span>
                        <div className=' flex items-center space-x-2'>
                          <span lassName=' flex space-x-1'>
                            <span className=' font-semibold'>Pays: </span>
                            <span>
                              {service.shippingAddresse.country.name}{' '}
                            </span>
                          </span>
                          <span lassName=' flex space-x-1'>
                            <span className=' font-semibold'>Ville: </span>
                            <span>{service.shippingAddresse.city} </span>
                          </span>
                        </div>
                        <div className=' flex space-x-2 items-center'>
                          <span lassName='  flex space-x-1'>
                            <span className=' font-semibold'>Email: </span>
                            <span>{service.shippingAddresse.email + ','}</span>
                          </span>
                          <span lassName='  flex space-x-1'>
                            <span className=' font-semibold'>Adresse: </span>
                            <span>{service.shippingAddresse.address}</span>
                          </span>
                        </div>
                      </li>
                      <li className=' justify-center flex items-center space-x-2'>
                        <button
                          onClick={() => navigate(`/commande/${service.id}`)}
                          title='Détails'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                            className='w-5 h-5 text-[#114cb3]'
                          >
                            <path d='M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z' />
                            <path
                              fillRule='evenodd'
                              d='M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z'
                              clipRule='evenodd'
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
              <Pagination
                documents={documents}
                productsPerPage={productsPerPage}
                currentPage={currentPage}
                paginate={paginate}
              />
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default Orders;
