import React from 'react';

const SuccessOrder = () => {
  return (
    <main>
      <div className=' w-[60%] h-full mx-auto flex items-center flex-col justify-center text-center'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill='currentColor'
          className='w-36 h-36 text-green-500'
        >
          <path
            fillRule='evenodd'
            d='M16.403 12.652a3 3 0 0 0 0-5.304 3 3 0 0 0-3.75-3.751 3 3 0 0 0-5.305 0 3 3 0 0 0-3.751 3.75 3 3 0 0 0 0 5.305 3 3 0 0 0 3.75 3.751 3 3 0 0 0 5.305 0 3 3 0 0 0 3.751-3.75Zm-2.546-4.46a.75.75 0 0 0-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z'
            clipRule='evenodd'
          />
        </svg>
        <h2 className=' font-medium text-[1.4rem]'>
          Merci pour votre commande !
        </h2>
        <p className=' mb-1 mt-2'>
          Nous avons bien reçu votre commande et nous la traiterons dans les
          plus brefs délais. Vous recevrez bientôt une confirmation par e-mail
          avec les détails de votre commande.
        </p>
        <p>
          Si vous avez des questions ou des préoccupations, n'hésitez pas à nous
          contacter. Nous sommes là pour vous aider !
        </p>
      </div>
    </main>
  );
};

export default SuccessOrder;
