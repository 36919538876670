import React, { useEffect, useState } from 'react';
import InputField from '../components/auth/InputField';
import AuthCrad from '../components/auth/AuthCrad';
import PrimaryButton from '../utilities/utils-components/PrimaryButton';
import { useAuth } from '../hooks/useAuth';
import Spinner from '../utilities/utils-components/Spinner';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { resetPassword, message, loading, error, success } = useAuth();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const queryParams = new URLSearchParams(window.location.search);

  const submitHandler = (e) => {
    e.preventDefault();

    const oobCode = queryParams.get('oobCode');
    resetPassword(password, confirmPassword, oobCode);
  };

  useEffect(() => {
    if (success) {
      toast.success(message, {
        position: 'top-center',
      });
      navigate('/connexion');
    }
  }, [success]);

  return (
    <AuthCrad title='Réintialisation du mot de passe'>
      {error && (
        <span className=' text-red-600 py-3 px-1 bg-red-50 block'>{error}</span>
      )}
      <Spinner loading={loading} />
      <form onSubmit={submitHandler} className=' flex flex-col space-y-4 mt-4'>
        <InputField
          title='Mot de passe'
          type='password'
          placeholder='Mot de passe'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputField
          title='Confirmation mot de passe'
          type='password'
          placeholder='Confirmation du mot de passe'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <PrimaryButton text='Réintialiser' />
      </form>
    </AuthCrad>
  );
};
