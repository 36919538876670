import React, { useState } from 'react';
import { companyTypes, countries } from '../data/data';
import SelectInput from './utils-components/SelectInput';
import { useNavigate } from 'react-router-dom';

function SearchBar() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(countries[0]);
  const [types, setTypes] = useState(companyTypes[0]);
  const [title, setTitle] = useState('');

  function submitHandler(event) {
    const location =
      selected.name === 'Toutes les localités' ? '' : selected.name;
    const company = types.name === "Types d'entreprises" ? '' : types.name;
    navigate(
      `/recherche?title=${title}&location=${location}&company=${company}`
    );
  }

  return (
    <div className='bg-white  hidden lg:flex w-full p-1 shadow-md  rounded-[5px]'>
      <div className='flex items-center basis-[45%]'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
          />
        </svg>

        <input
          onChange={(e) => setTitle(e.target.value)}
          className='p-2 flex-1 w-full outline-none'
          type='text'
          placeholder={`Taper le nom d'une entreprise`}
        />
      </div>
      <div className='flex items-center basis-[45%]'>
        <div className='flex-1 '>
          <SelectInput
            inputEls={companyTypes}
            setSelected={setTypes}
            selected={types}
            iconColor='#ffffff'
          />
        </div>

        <div className='flex-1 '>
          <SelectInput
            inputEls={countries}
            setSelected={setSelected}
            selected={selected}
            iconColor='#ffffff'
          />
        </div>
      </div>
      <div>
        <button
          onClick={submitHandler}
          className=' bg-[#d60e0e] text-white w-full h-full px-10 py-2'
        >
          Recherche
        </button>
      </div>
    </div>
  );
}

export default SearchBar;
