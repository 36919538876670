import React from 'react';
import PrimaryButton from '../../utilities/utils-components/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const Apropos = () => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate('/connexion');
  };

  return (
    <div className=' py-8'>
      <h2 className=' text-[1.1rem] md:text-[1.6rem] font-semibold capitalize'>
        A propos de nous
      </h2>
      <div className=' grid grid-cols-1 xl:grid-cols-2 gap-6 my-5'>
        <div>
          <img
            src='https://images.unsplash.com/photo-1587440871875-191322ee64b0?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            alt='image'
            className=' h-[40vh] xl:h-[50vh] w-full object-cover rounded-md'
          />
        </div>
        <div>
          <p className=' text-[0.95rem] md:text-[1.1rem]'>
            Notre plateforme simplifie la mise en relation entre entreprises et
            clients, facilitant ainsi la recherche et l'achat de services. Avec
            pour objectif la transparence et l'efficacité, nous proposons une
            expérience fluide, que vous soyez un professionnel en quête de
            clients ou un client à la recherche de services. Grâce à notre
            réseau étendu d'entreprises vérifiées et de prestataires qualifiés,
            explorez une diversité d'offres adaptées à vos besoins. Notre
            interface conviviale, nos outils de recherche avancés et notre
            processus de commande sécurisé garantissent votre satisfaction à
            chaque étape. Rejoignez-nous dès aujourd'hui pour simplifier vos
            transactions commerciales et atteindre vos objectifs.
          </p>
          <div className=' mt-3'>
            <PrimaryButton onClick={clickHandler} text='Demarrer' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apropos;
