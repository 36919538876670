import React from 'react';

const PageHeader = () => {
  return (
    <div className=' h-[20vh] relative flex justify-center items-center text-center'>
      <div className=' z-10 absolute h-full w-full bg-[#000000ad]'></div>
      <div className=' top-0 left-0 right-0 bottom-0 absolute h-full w-full'>
        <img
          src='https://plus.unsplash.com/premium_photo-1661762833325-9b7e1c0b4abf?q=80&w=2110&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
          alt=''
          className='h-full w-full object-cover object-center'
        />
      </div>
      <div className=' relative text-white z-20 '>
        <h1 className='  text-[1.4rem]'>FAQ</h1>
        <p className=' text-[1.2rem] '>
          Questions fréquemment posées : réponses à vos interrogations
          courantes.
        </p>
      </div>
    </div>
  );
};

export default PageHeader;
