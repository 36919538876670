import React from 'react';

const Description = ({ service }) => {
  return (
    <div className='bg-white shadow-sm  p-4 rounded-sm'>
      <h2 className=' text-[1.1rem] md:text-[1.2rem] font-semibold'>
        Description
      </h2>
      <p>{service.description}</p>
    </div>
  );
};

export default Description;
