import { faStar } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../utilities/utils-components/PrimaryButton';
import { priceFormatter } from '../../utils/priceFomatter';
import ProductStar from '../../utilities/utils-components/ProductStar';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Detailheader = ({ isIncludes, bookingDays, seller, service }) => {
  const navigate = useNavigate();
  const [lastImageIndex, setLastImageIndex] = useState(2);
  const [indexOfFirstImage, setIndexOfFirstImage] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const currentImages = service.gallery.slice(
    indexOfFirstImage,
    lastImageIndex
  );

  const next = () => {
    if (service.gallery.length === lastImageIndex) {
      return;
    } else {
      setLastImageIndex((prevIndex) => prevIndex + 1);

      setIndexOfFirstImage((prevIndex) => prevIndex + 1);
      // setActiveIndex(index + indexOfFirstImage);
    }
  };

  const prev = () => {
    if (indexOfFirstImage === 0) {
      return;
    } else {
      setLastImageIndex((prevIndex) => prevIndex - 1);
      setIndexOfFirstImage((prevIndex) => prevIndex - 1);
    }
  };

  function clickHandler() {
    const totalAmout = Number(service.price);
    const brutTotalAmout = Number(service.price);
    const reduction = 0;
    if (
      isIncludes ||
      (bookingDays.length === 0 &&
        (service.companyTypes === 'Hôtel' ||
          service.companyTypes === 'Location de véhicule'))
    ) {
      toast.error('Période selectionnée invalide', {
        position: 'top-center',
      });
    } else {
      const product = {
        product: {
          ...service,
          totalAmout,
          qty: 1,
          reduction,
          brutTotalAmout,
          normalePrice: brutTotalAmout,
          shippingFee: 0,
          deleveryTime: '0',
          bookingDays,
        },
        seller: { name: seller.userName, id: seller.id },
        isService: true,
      };
      localStorage.setItem('product', JSON.stringify(product));
      navigate('/addresse/client');
    }
  }

  return (
    <div>
      <div className=' h-[350px] md:h-[650px] relative mt-6 w-full flex items-center justify-center'>
        <div className=' absolute  h-full w-full'>
          <img
            src={
              service.gallery[
                indexOfFirstImage > 0
                  ? lastImageIndex - indexOfFirstImage + activeIndex - 1
                  : activeIndex
              ]
            }
            alt=''
            className='  h-full w-full object-cover'
          />
        </div>
        <div className=' absolute z-10 bg-[#0000006c]  h-full w-full'></div>
        <div className=' h-[350px] md:h-[650px] p-3 z-40  relative w-[350px] md:w-[650px]'>
          <img
            src={
              service.gallery[
                indexOfFirstImage > 0
                  ? lastImageIndex - indexOfFirstImage + activeIndex - 1
                  : activeIndex
              ]
            }
            alt=''
            className=' rounded-md h-full w-full object-cover'
          />
          <div className='flex items-center absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bottom-0 justify-center mt-4'>
            <button
              disabled={indexOfFirstImage === 0}
              onClick={prev}
              className={`${
                indexOfFirstImage === 0 ? 'bg-[#f1f1f189]' : 'bg-[#f1f1f1]'
              }   h-8 w-4  flex items-center`}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
                className='w-10 h-10'
              >
                <path
                  fillRule='evenodd'
                  d='M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z'
                  clipRule='evenodd'
                />
              </svg>
            </button>

            {currentImages.map((img, index) => (
              <div
                onClick={() => {
                  if (indexOfFirstImage > 0) {
                    setActiveIndex(
                      lastImageIndex - indexOfFirstImage + index - 2
                    );
                  } else {
                    setActiveIndex(index + indexOfFirstImage);
                  }
                }}
                key={index}
                className={`md:h-20 h-16 w-20 md:w-32 border-4  rounded-md  mx-2 cursor-pointer ${
                  activeIndex === index ? 'border-[#399b00]' : 'border-white'
                }`}
              >
                <img
                  src={img}
                  alt='image'
                  className=' h-full w-full rounded-md '
                />
              </div>
            ))}
            <button
              disabled={service.gallery.length === lastImageIndex}
              onClick={next}
              className={`${
                service.gallery.length === lastImageIndex
                  ? 'bg-[#f1f1f189]'
                  : 'bg-[#f1f1f1]'
              }   h-8 w-4  flex items-center`}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
                className='w-5 h-5'
              >
                <path
                  fillRule='evenodd'
                  d='M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className=' bg-white'>
        <div className=' flex-wrap items-start flex justify-between w-[95%] xl:w-[90%] 2xl:w-[80%] mx-auto py-4'>
          <div className=' basis-[100%] md:basis-3/5'>
            <div className=' flex justify-between '>
              <h1 className=' font-bold text-[1rem] md:text-lg mb-2 '>
                {service.title}{' '}
              </h1>
              <div className=' flex items-center space-x-1'>
                <h2 className=' font-bold text-[1.2rem]'>{service.star}</h2>
                <ProductStar rating={service.star} />
              </div>
            </div>
            <p className=' text-[1rem] opacity-65'>
              {service.shortdescription}
            </p>
          </div>

          <div className=' mt-3 md:mt-0 flex flex-col'>
            <span className=' font-semibold text-[1.2rem]'>
              {priceFormatter(service.price)} FCFA
            </span>
            <PrimaryButton onClick={clickHandler} text={'Commander'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detailheader;
