import { db } from '../firebase/config';
import { doc, onSnapshot, collection } from 'firebase/firestore';
import { useEffect, useReducer } from 'react';

const initialState = {
  seller: null,
  service: null,
  document: [],
  isPending: true,
  error: null,
  success: null,
};

const docReducer = (state, action) => {
  switch (action.type) {
    case 'IS_PENDING':
      return { ...state, isPending: true, error: null, success: null };
    case 'ADD_DOC':
    case 'UPDATED_DOC':
      return {
        service: action.payload,
        document: [...action.payload2],
        seller: action.payload3,
        isPending: false,
        error: null,
        success: true,
      };
    case 'ERROR':
    case 'REMOVE_DOC':
      return {
        seller: null,
        service: null,
        document: [],
        isPending: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};

export const useDocRelated = (document, docCollection, id) => {
  const [docResponse, dispatch] = useReducer(docReducer, initialState);

  useEffect(() => {
    const getDocument = async () => {
      dispatch({ type: 'IS_PENDING' });

      try {
        const docRef = doc(db, document, id);

        onSnapshot(docRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data) {
              const ref = collection(db, docCollection);
              onSnapshot(ref, (snapshot) => {
                let results = [];

                snapshot.docs.forEach((doc) => {
                  results.push({ id: doc.id, ...doc.data() });
                });

                results = results.filter(
                  (item) => item.uid === data.uid && item.id !== id
                );

                const userRef = doc(db, 'users', data.uid);

                onSnapshot(userRef, (docSnapshot) => {
                  if (docSnapshot.exists()) {
                    const user = docSnapshot.data();
                    if (user) {
                      dispatch({
                        type: 'ADD_DOC',
                        payload: { id, ...data },
                        payload2: results,
                        payload3: { id: data.uid, ...user },
                      });
                    }
                  }
                });
              });
            }
          }
        });
      } catch (error) {
        dispatch({ type: 'ERROR', payload: error.message });
      }
    };

    getDocument();

    return () => {};
  }, [document, id]);

  return {
    docResponse,
  };
};
