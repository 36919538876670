export const useFooterLink = (user) => {
  const Links = [
    {
      title: 'Entreprise',
      link: [
        {
          title: 'Qui sommes nous?',
          url: '/qui-somme-nous',
        },
        {
          title: 'Termes & Conditions',
          url: '/termes-conditions',
        },
        {
          title: 'Faqs',
          url: '/faqs',
        },
      ],
    },
    {
      title: 'Support',
      link: [
        {
          title: 'Contactez-nous',
          url: '/contactez-nous',
        },
        {
          title: 'Devenir prestataire',
          url: `${user ? '/profil/edit' : '/inscription'} `,
        },
        {
          title: 'Trouver un prestatire',
          url: '/entreprise',
        },
      ],
    },
  ];

  return { Links };
};
